import React from "react";
import { Backdrop, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationDisplay from "../Generic/NotificationDisplay";
import PageTitle from "../Generic/PageTitle";
import { useTheme } from "@mui/material";

const ChartBackdrop = ({ title, showBackdrop, closeOverlay, children }) => {
  const theme = useTheme();
  const padding = "2rem";
  return (
    <>
      <Backdrop
        open={showBackdrop}
        inert={showBackdrop}
        aria-hidden={!showBackdrop}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.getContrastText(
            theme.palette.background.default
          ),
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          // paddingLeft: `${padding}px`,
          // paddingRight: `${padding}px`,
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <NotificationDisplay />
        <PageTitle rightSideElement={null}>
          <div
            style={{
              paddingTop: 0,
              display: "flex",
              flexDirection: "row",
              color: theme.palette.getContrastText(
                theme.palette.background.default
              ),
            }}
          >
            {title}
          </div>
        </PageTitle>
        <IconButton
          onClick={closeOverlay}
          style={{ position: "absolute", top: padding, right: padding }}
        >
          <CloseIcon />
        </IconButton>

        <div
          inert
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {children}
        </div>
      </Backdrop>
    </>
  );
};

export default ChartBackdrop;
