import React, { useEffect, useState, useCallback } from "react";
import Draggable from "react-draggable";

import {
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  Typography,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import {
  fetchChartLink,
  fetchMyLinks,
  fetchUserAccessibleCampuses,
  deleteChartLink,
  updateChartLinkName,
} from "../actions/api";
import { HEADER_HEIGHT, BORDER_RADIUS_DRAWERS } from "../../constants";
import { useNotification } from "../Generic/NotificationContext";

const FavoriteDrawer = (props) => {
  const { isOpen, children, handleClose, reload } = props;
  const { addNotification, addError } = useNotification();

  const [linkLoading, setLinkLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLinkHashCode, setDeleteLinkHashCode] = useState(null);
  const [myLinks, setMyLinks] = useState([]);
  // States for renaming a link:
  const [editingLink, setEditingLink] = useState(null);
  const [newLinkName, setNewLinkName] = useState("");

  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const theme = useTheme();

  const fetchCampuses = useCallback(async () => {
    try {
      const data = await fetchUserAccessibleCampuses();
      if (data?.length > 0) {
        return data;
      } else {
        addError(t("No campuses available for this user."));
        return null;
      }
    } catch (error) {
      addError(t("Error fetching campuses data."));
      return null;
    }
  }, [addError, t]);

  const loadFavorite = (hash, index) => {
    // Write to storage and reload chart page
    handleRetrieveLink(hash, index);
  };

  const handleRetrieveLink = useCallback(
    async (hashCode, index = null) => {
      if (linkLoading) return;

      setLinkLoading(true);

      try {
        const response = await fetchChartLink(hashCode);

        if (response?.success) {
          const { page_data } = response.data;
          // For charts: continue only if all values are available
          if (
            page_data.type === "chart" &&
            page_data.selectedCampusId &&
            page_data.selectedChartView &&
            page_data.selectedChartDef &&
            page_data.selectedTargetId &&
            page_data.startDateTime &&
            page_data.endDateTime
          ) {
            // Get campus id and compare with accessible campuses
            const campusId = page_data.selectedCampusId;
            const campuses = await fetchCampuses();
            const campusExists = campuses.some(
              (campus) => campus.id === campusId
            );
            if (campusExists) {
              // Update localStorage
              localStorage.setItem(
                "chart_favorite_type",
                JSON.stringify("chart")
              );
              localStorage.setItem(
                "chart_favorite_selectedCampusId",
                JSON.stringify(campusId)
              );
              localStorage.setItem(
                "chart_favorite_selectedChartView",
                JSON.stringify(page_data.selectedChartView)
              );
              localStorage.setItem(
                "chart_favorite_selectedChartDef",
                JSON.stringify(page_data.selectedChartDef)
              );
              localStorage.setItem(
                "chart_favorite_selectedTargetId",
                JSON.stringify(page_data.selectedTargetId)
              );
              localStorage.setItem(
                "chart_favorite_startDateTime",
                JSON.stringify(page_data.startDateTime)
              );
              localStorage.setItem(
                "chart_favorite_endDateTime",
                JSON.stringify(page_data.endDateTime)
              );

              const storageEvent = new Event("localStorageUpdate");
              window.dispatchEvent(storageEvent);
            } else {
              addError(
                "The link refers to a campus without access rights or it does not exist."
              );
            }
          }
        } else {
          addError("Failed to retrieve the link.");
        }
      } catch (error) {
        console.error("Error retrieving link:", error);
        addError("Error occurred while retrieving the link.");
      } finally {
        setLinkLoading(false);
      }
    },
    [linkLoading, addError, fetchCampuses]
  );

  // PATCH: Rename Feature
  const handleEditClick = (link) => {
    setEditingLink(link.hash_code);
    setNewLinkName(link.name);
  };

  const handleSaveEdit = async (hashCode) => {
    if (!newLinkName.trim()) {
      addError("Link name cannot be empty.");
      return;
    }
    try {
      const response = await updateChartLinkName(hashCode, newLinkName);
      if (response?.success) {
        setMyLinks((prevLinks) =>
          prevLinks.map((link) =>
            link.hash_code === hashCode ? { ...link, name: newLinkName } : link
          )
        );
        addNotification("Link name updated successfully.");
        setEditingLink(null);
      } else {
        addError("Failed to update link name.");
      }
    } catch (error) {
      console.error("Error updating link name:", error);
      addError("Error occurred while updating the link name.");
    }
  };

  const handleFetchMyLinks = useCallback(async () => {
    try {
      const response = await fetchMyLinks();
      if (response?.success) {
        const sortedLinks = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setMyLinks(sortedLinks);
      } else {
        addError("Failed to fetch your links.");
      }
    } catch (error) {
      console.error("Error fetching links:", error);
      addError("Error occurred while fetching your links.");
    }
  }, [addError]);

  useEffect(() => {
    if (isOpen || reload) {
      handleFetchMyLinks();
    }
  }, [isOpen, reload, handleFetchMyLinks]);

  const handleDeleteLink = async () => {
    if (!deleteLinkHashCode) return;

    try {
      const response = await deleteChartLink(deleteLinkHashCode);
      if (response?.success) {
        addNotification("Link deleted successfully.");
        setMyLinks(
          myLinks.filter((link) => link.hash_code !== deleteLinkHashCode)
        );
      } else {
        addError("Failed to delete the link.");
      }
    } catch (error) {
      console.error("Error deleting link:", error);
      addError("Error occurred while deleting the link.");
    }

    setDeleteDialogOpen(false);
    setDeleteLinkHashCode(null);
  };

  // Updated handleCopyLink with fallback method
  const handleCopyLink = (link) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          addNotification(`Link copied to clipboard: ${link}`);
        })
        .catch((err) => {
          console.error("Clipboard write failed:", err);
          addError("Failed to copy the link.");
        });
    } else {
      // Fallback using a temporary textarea
      const textArea = document.createElement("textarea");
      textArea.value = link;
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          addNotification(`Link copied to clipboard: ${link}`);
        } else {
          addError("Failed to copy the link.");
        }
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
        addError("Failed to copy the link.");
      }
      document.body.removeChild(textArea);
    }
  };

  if (!isOpen) return null; // Do not render if not open

  return (
    <Draggable handle=".draggable-handle">
      <div
        style={{
          position: "fixed",
          top: isMobile ? null : 0,
          right: 0,
          bottom: isMobile ? 0 : null,
          paddingTop: isMobile ? "0px" : "120px",
          marginTop: isMobile
            ? "0px"
            : `calc(${HEADER_HEIGHT} + ${isTablet ? "4.5rem" : "7rem"})`,
          borderRadius: isMobile
            ? `${BORDER_RADIUS_DRAWERS}px ${BORDER_RADIUS_DRAWERS}px 0 0`
            : `${BORDER_RADIUS_DRAWERS}px 0 0 ${BORDER_RADIUS_DRAWERS}px`,
          border: `1px solid ${alpha(theme.palette.primary.dark, 0.6)}`,
          borderRight: isMobile ? null : "none",
          boxSizing: "border-box",
          boxShadow: `2px 0px 50px ${alpha(theme.palette.primary.dark, 0.1)}`,
          width: isMobile ? "100%" : "550px",
          background: theme.palette.background.default,
          zIndex: 1300,
          transition: "transform 0.3s ease-in-out",
          transform: "translateX(0)",
          padding: "1rem",
        }}
      >
        {/* Draggable Handle */}
        <div className="draggable-handle" style={{ cursor: "move" }}>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", top: "16px", right: "16px" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom>
            {t("SHORT_LINKS")}
          </Typography>
        </div>
        {/* Scrollable container for direct links */}
        <div
          style={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 180px)",
            width: "100%",
          }}
        >
          {myLinks.map((link, index) => {
            return (
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  margin: 1,
                }}
                key={link.hash_code}
                spacing={1}
              >
                <Stack
                  sx={{
                    height: "32px",
                  }}
                  direction="row"
                  spacing={1}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyLink(link.target_page);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => loadFavorite(link.hash_code, index)}
                  >
                    <CachedIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteDialogOpen(true);
                      setDeleteLinkHashCode(link.hash_code);
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Stack>
                {editingLink === link.hash_code ? (
                  <>
                    <TextField
                      value={newLinkName}
                      onChange={(e) => setNewLinkName(e.target.value)}
                      multiline
                      maxRows={2}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          padding: "1px",
                          "& fieldset": {
                            borderColor: theme.palette.text.secondary,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.secondary,
                        },
                      }}
                      autoFocus
                    />
                    <IconButton onClick={() => handleSaveEdit(link.hash_code)}>
                      <SaveIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <TextField
                      id="outlined-basic"
                      value={link.name}
                      multiline
                      maxRows={2}
                      InputProps={{
                        readOnly: true,
                        sx: {
                          "& .MuiInputBase-input": {
                            color: theme.palette.text.secondary,
                            padding: 0,
                            height: "100%",
                            fontSize: "0.875rem",
                            textAlign: "center",
                            overflow: "hidden",
                          },
                        },
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          padding: "1px",
                          "& fieldset": {
                            borderColor: theme.palette.text.secondary,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.secondary,
                        },
                      }}
                      variant="outlined"
                    />
                    <IconButton onClick={() => handleEditClick(link)}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Stack>
            );
          })}
        </div>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>{t("Confirm Deletion")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "Are you sure you want to delete this link? This action cannot be undone."
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              {t("CANCEL")}
            </Button>
            <Button
              onClick={handleDeleteLink}
              color="error"
              variant="contained"
            >
              {t("CONFIRM")}
            </Button>
          </DialogActions>
        </Dialog>
        {linkLoading}
        {children}
      </div>
    </Draggable>
  );
};

export default FavoriteDrawer;
