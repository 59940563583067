import React, { useMemo, memo } from "react";
import { Typography, Card, CardContent } from "@mui/material";
import HistorianUPlot from "./HistorianUPlot";
import { getNightSpanTimestamps } from "./uplotFuncs";
import { useTheme } from "@mui/material";
import { PAPER_ELEVATION, BORDER_RADIUS } from "../../constants";
import { Translation } from "react-i18next";

/**
 * Represent a chart with a header bar and optional KPI boxes and description.
 */
const ChartBlock = ({
  blockSize,
  title,
  chart,
  chartSpec,
  chartDefs,
  chartData,
  annotation,
  chartDescriptions,
  kpiDefs,
  kpiData,
  chartKey,
  chartViewAttr,
  divKey,
  selectedCampus,
  // complete datasets (all blocks)
  completeSets,
}) => {
  // 1) Always call hooks at the top
  const theme = useTheme();

  // We can do an early *check*, but do NOT return yet:
  const blockSizeValid = blockSize && blockSize.parent;

  /**
   * Prepare time-series data for shading (night spans, etc.).
   * We’ll call this inside the child, so we just define it here.
   */
  const splitBoolNumberData = (_defs, _data) => {
    // console.log("[splitBoolNumberData] Checking input data:", _data);
    const dateWithTimestamps = getNightSpanTimestamps({
      sunset: { hour: 19, min: 0 },
      sunrise: { hour: 7, min: 0 },
      data: _data,
      tz: selectedCampus?.campusTimeZone,
    });
    return {
      origData: _data,
      origChartDefs: _defs,
      dateWithTimestamps,
    };
  };

  /**
   * 2) Also call `useMemo` unconditionally, whether or not blockSize is valid
   */
  const resolvedChartData = useMemo(() => {
    if (Array.isArray(chartData)) return chartData;
    if (chartData && Array.isArray(chartData.data)) return chartData.data;
    return [];
  }, [chartData]);

  /**
   * If `blockSizeValid` is false, we can safely return after the hooks are called
   */
  if (!blockSizeValid) {
    return (
      <Typography variant="body2" color="textSecondary">
        No chart layout (blockSize) available
      </Typography>
    );
  }

  // Now we can safely destructure
  const { kpi, parent } = blockSize;
  const { titleHeight, padding } = parent;

  console.log(
    `%c[ChartBlock Render] chartKey=${chartKey}`,
    "color: orange; font-weight: bold;",
    {
      blockSize,
      title,
      chart,
      chartData,
      annotation,
      completeSets,
    }
  );

  /**
   * A memoized child component for the chart logic
   */
  const ChartSelectMemo = memo(
    ({ childKey, data, attr, divK, chart, allAttr, annotation }) => {
      console.log(
        `%c[ChartSelectMemo] childKey=${childKey}, data=`,
        "color: green; font-weight: bold;",
        data
      );

      // 1) Check if `data` is actually an array
      if (!Array.isArray(data)) {
        console.warn(
          "[ChartSelectMemo] data is not an array => invalid format"
        );
        return (
          <Translation>
            {(t) => (
              <Typography variant="body2" color="textSecondary" component="div">
                {t("INVALID_DATA_FORMAT") || "Invalid data format"}
              </Typography>
            )}
          </Translation>
        );
      }

      // 2) If empty => "NO_TIMESERIES_DATA"
      if (data.length === 0) {
        console.log("[ChartSelectMemo] data is empty => no timeseries data");
        return (
          <Translation>
            {(t) => (
              <Typography variant="body2" color="textSecondary" component="div">
                {t("NO_TIMESERIES_DATA") || "No timeseries data"}
              </Typography>
            )}
          </Translation>
        );
      }

      // 3) Check if all zero => treat as no data
      const allZeros = data.every(
        (subArray) =>
          Array.isArray(subArray) && subArray.every((val) => val === 0)
      );
      if (allZeros) {
        console.log("[ChartSelectMemo] data is all zeros => no data");
        return (
          <Translation>
            {(t) => (
              <Typography variant="body2" color="textSecondary" component="div">
                {t("NO_TIMESERIES_DATA") || "No timeseries data"}
              </Typography>
            )}
          </Translation>
        );
      }

      // 4) Otherwise data is valid => render the chart
      return (
        <HistorianUPlot
          childKey={childKey}
          syncKey={data[0]?.[0] || "no-sync-key"}
          tz={selectedCampus?.campusTimeZone}
          defsAndData={splitBoolNumberData(attr, data)}
          chartSpec={chartSpec}
          chartDescriptions={chartDescriptions}
          title=""
          data={data}
          divK={divK}
          charAttr={chart}
          viewAttr={allAttr}
          blockSize={blockSize}
          annotation={annotation}
          // new
          completeSets={completeSets}
          // completeSets={{
          //   charts: charts,
          //   dataSets: dataSets,
          //   annotations: annotations,
          //   selectedCampus:selectedCampus
          // }}
        />
      );
    }
  );

  // Now we return the JSX for the "valid" case
  return (
    <Card
      id="subGraphContainer"
      sx={{
        marginBottom: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "left",
        borderRadius: `${BORDER_RADIUS}px`,
        padding: `${padding}px`,
      }}
      elevation={PAPER_ELEVATION}
      key={`subKey-${chartKey}`}
    >
      <CardContent
        sx={{
          padding: 0,
          margin: 0,
          ":last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          style={{
            width: "100%",
            height: titleHeight,
            color: theme.palette.getContrastText(
              theme.palette.background.paper
            ),
            fontWeight: 900,
            textTransform: "uppercase",
          }}
        >
          {title ?? "tbd"}
        </Typography>

        <div
          style={{
            width: parent.width,
            height: parent.height,
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "left",
          }}
        >
          {/* Chart section */}
          <div
            key={`a-${chartKey}`}
            id="subGraphContainerChart"
            style={{
              width: parent.width,
              height: parent.height,
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "left",
            }}
          >
            <ChartSelectMemo
              childKey={`aa-${chartKey}`}
              data={resolvedChartData}
              annotation={annotation}
              attr={chartDefs}
              allAttr={chartViewAttr}
              divK={divKey}
              chart={chart}
            />
          </div>

          {/* KPI area (if needed) */}
          <div
            key={`b-${chartKey}`}
            style={{
              boxSizing: "border-box",
              width: kpi?.kpiWidth || 0,
              height: parent.height,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {kpiDefs?.map((k, idx) => (
              <div key={`kpi-${chartKey}-${idx}`}>
                {/* KPI logic or placeholders */}
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ChartBlock;
