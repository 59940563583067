import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  timeZoneData,
  syncCursers,
  formatDates,
  formatAxes,
  defineYSeries,
  exportPng,
  makeChart,
  drawZigZag,
} from "./uplotFuncs";
import {
  convertLocalValToUtcEpoch,
  convertUtcValToLocalEpoch,
} from "./chartFuncs";
import { fetchChartAnnotations } from "../actions/api";
import ChartDescription from "./ChartDescription";
import ChartActionMenu from "./ChartActionMenu";
import Annotation from "./Annotation";
import "./Uplot.css";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import DrawIcon from "@mui/icons-material/Draw";
import ChartBackdrop from "./ChartBackdrop";

/**
 * A component that utilizes uPlot to render historical data with performance optimizations using hooks and batched updates.
 * Based on example from https://codesandbox.io/p/sandbox/uplot-react-6ykeb
 * @param {Array} data - Array with data and ids for each chart: {data: Array with data prepared for uPlot, ids:Array with ids}
 * @param {Object} chartDefs - Object with chart definitions
 * @param {Array} yArrays - The array of y-axis data. One or more series.
 * @param {Object} options - Chart options containing title (Str), chartType (Str), colors (Array), points (Array) with names
 * @param {Str} target - Name of selected model target
 * @param {object} charAttr - Information about the chart template
 * @param {object} viewAttr - Information about the chart
 *  */
const HistorianUPlot = ({
  defsAndData,
  chartSpec,
  chartDescriptions,
  blockSize,
  title,
  syncKey,
  tz,
  divK,
  charAttr,
  viewAttr,
  // annotation,
  completeSets,
}) => {
  const debug = false;
  // Data and definitions
  const { origChartDefs, dateWithTimestamps, origData } = defsAndData;
  const { dateRangeSelection, selectedCampus } = completeSets;
  const { campusTimeZone } = selectedCampus;
  if (debug)
    console.log(
      "HistorianUPlot - inputs",
      defsAndData,
      chartSpec,
      divK,
      charAttr,
      viewAttr,
      completeSets
    );
  // Dimensioning data
  const { parent, chart } = blockSize;
  const { chartWidth, chartHeight } = chart;

  const { workspaceWidth, split } = parent;
  const theme = useTheme();
  const { t } = useTranslation();
  const divRef = useRef(null);
  const [plot, setPlot] = useState(null);
  const [enabled, setEnabled] = useState(Array(origData.length).fill(true)); // incl time
  const [showAnnotation, setShowAnnotation] = useState(null); // null, emptyAnnotaion {} or annotation {...}
  // Later
  // eslint-disable-next-line no-unused-vars
  const [showFullScreen, setShowFullScreen] = useState(false);
  // Annotation for this chart
  const [annotation, setAnnotation] = useState([]);
  const [annotationReady, setAnnotationReady] = useState(false);

  // Update if: when page is loaded and after the annotation overlay is closed
  const [updateAnnotation, setUpdateAnnotation] = useState(true);

  const closeAnnotationOverlayAndUpdate = () => {
    setUpdateAnnotation(true);
    setShowAnnotation(null);
    // setShowFullScreen(false);
  };
  const closeOverlay = () => {
    // setUpdateAnnotation(true)
    setShowAnnotation(null);
    // setShowFullScreen(false);
  };

  /**
   * Annotation fetching effect:
   * We re-fetch whenever 'charts', 'startDateTime', or 'endDateTime' changes.
   */
  useEffect(() => {
    // We only fetch if we have some charts + valid date range
    if (
      !origChartDefs ||
      origChartDefs.length === 0 ||
      !dateRangeSelection.startDateTime
    ) {
      if (debug) {
        console.log(
          "No charts or missing startDate => set annotations=[] and skipping fetch"
        );
      }
      setAnnotation([]);
      return;
    }

    // function to do the actual fetch calls
    const fetchAnnotations = async (idList, startDt, endDt) => {
      try {
        if (debug) {
          console.log(
            "fetchAnnotations => calling fetchChartTimeSeriesData with:",
            {
              idList,
              startDt,
              endDt,
            }
          );
        }
        const data = await fetchChartAnnotations(idList, startDt, endDt);
        return data;
      } catch (error) {
        console.error(
          "ChartGraphWorkspace: Error fetching annotations:",
          error
        );
        return [];
      }
    };

    // function that updates dataSets for all charts
    const updateAnnotations = async (_series, _start, _end) => {
      if (debug) {
        console.log(
          "updateAnnotations => start fetching for chart series:",
          _series
        );
        console.log("updateAnnotations => dateRange:", _start, "to", _end);
      }
      setAnnotationReady(false);
      try {
        // flatten points
        const pointList = _series.flatMap((serie) => serie.points);

        const getAnnotations = async () => {
          if (pointList.length > 0) {
            return await fetchAnnotations(pointList, _start, _end);
          } else {
            return [];
          }
        };

        const newAnnotationValues = await getAnnotations();

        if (debug) {
          console.log(
            "updateAnnotations => done, newAnnotationValues:",
            newAnnotationValues
          );
        }
        // Convert UTC epochs to local epochs

        const convertedAnnotations = newAnnotationValues.map((entry) => {
          return {
            ...entry,
            end_timestamp: convertUtcValToLocalEpoch(
              entry.end_timestamp,
              campusTimeZone
            ),
            start_timestamp: convertUtcValToLocalEpoch(
              entry.start_timestamp,
              campusTimeZone
            ),
            annotation_data: {
              ...entry.annotation_data,
              end_timestamp: convertUtcValToLocalEpoch(
                entry.annotation_data.end_timestamp,
                campusTimeZone
              ),
              start_timestamp: convertUtcValToLocalEpoch(
                entry.annotation_data.start_timestamp,
                campusTimeZone
              ),
            },
          };
        });
        // console.log("---------EPOCH- newAnnotationValues--", newAnnotationValues,convertedAnnotations)
        setAnnotation(convertedAnnotations);
      } catch (error) {
        console.error("Error updating annotations:", error);

        setAnnotation(Array.from({ length: origChartDefs.length }, () => []));
      } finally {
        setAnnotationReady(true);
        if (debug) console.log("updateAnnotations => all done, dataReady=true");
      }
    };

    if (updateAnnotation) {
      // To run the query, convert local epochs to UTC epochs in sec, based on the campus timezone
      const firstTs = origData[0][0];
      const lastTs = origData[0][origData[0].length - 1];
      const firstTsConverted = convertLocalValToUtcEpoch(
        firstTs,
        campusTimeZone
      );
      const lastTsConverted = convertLocalValToUtcEpoch(lastTs, campusTimeZone);
      // console.log("---------EPOCH---", firstTs, firstTsConverted, lastTs, lastTsConverted)

      updateAnnotations(origChartDefs, firstTsConverted, lastTsConverted);
      setUpdateAnnotation(false);
    }

    // Always re-fetch if charts or date changes or if annotation was changed
  }, [
    origChartDefs,
    origData,
    debug,
    updateAnnotation,
    campusTimeZone,
    dateRangeSelection.startDateTime,
  ]);

  // eslint-disable-next-line no-unused-vars
  const [opts, setOpts] = useState({
    id: `${divK}-chart1-float`,
    width: chartWidth,
    height: chartHeight,
    padding: [
      10,
      workspaceWidth < split ? 0 : 30,
      workspaceWidth < split ? 40 : 20,
      workspaceWidth < split ? 0 : 30,
    ], // top,right, bottom, left
    legend: {
      show: true,
    },
    series: [
      {
        label: t("TIME"),
        // stroke: "blue",
        // Format time output
        value: (self, rawValues) => {
          const date = new Date(rawValues * 1000); // needs millis

          //Take locale from browser
          const userLocale = navigator.language || navigator.userLanguage;
          return date.toLocaleString(userLocale); // "ch-DE"
        },
      },
      ...defineYSeries(origChartDefs, theme),
    ],
    ...timeZoneData(tz),
    ...syncCursers(syncKey),
    ...formatDates(),
    ...formatAxes(origChartDefs, theme),
  });

  const handleAnnotationComponent = (annotation, u) => {
    setShowAnnotation(annotation);
  };

  const annoCoords = (_anno, _u, _amplitude, _index = 0) => {
    const { start_timestamp, end_timestamp } = _anno;
    const distance = 4;
    const startHeight = 35 + _index * distance * _amplitude;

    const startPos = _u.valToPos(start_timestamp, "x", true);
    const endPos = _u.valToPos(end_timestamp, "x", true);
    // Calculate the midpoint for the x position
    const midX = startPos + (endPos - startPos) / 2;

    // Determine the y position (height plus amplidute)
    const midY = startHeight + _amplitude;
    return {
      startX: startPos,
      endX: endPos,
      startHeight: startHeight,
      midX: midX,
      midY: midY,
    };
  };

  const drawAnnotations = useCallback(({ annotation, theme }) => {
    // constants for drawing
    const amplitude = 2;
    const step = 2;

    // Create a tooltip element if one doesn't already exist
    let tooltip = document.getElementById("tooltip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.id = "tooltip";
      tooltip.style.position = "absolute";
      tooltip.style.padding = "4px 8px";
      tooltip.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      tooltip.style.color = "#fff";
      tooltip.style.borderRadius = "4px";
      tooltip.style.pointerEvents = "none";
      tooltip.style.fontSize = "13px";
      tooltip.style.display = "none";
      document.body.appendChild(tooltip);
    }
    // TODO: Add icon as a independent element like the tooltip above
    const drawAnnos = (u) => {
      const { ctx } = u;

      ctx.save();

      const drawZigZagLine = (xStart, xEnd, startHeight, segments) => {
        // Adjust the number of zig-zag segments
        drawZigZag(ctx, xStart, xEnd, startHeight, segments, theme);
      };

      const iconPath = new Path2D(
        "M12 2c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8C20 5.22 16.2 2 12 2m1 13h-2v-2h2zm0-4h-2V6h2z"
      );

      for (const [index, anno] of annotation.entries()) {
        const coords = annoCoords(anno, u, amplitude, index);

        // calculate the number of segments
        const length = coords.endX - coords.startX;
        const segments = Math.floor(length / step);

        drawZigZagLine(
          coords.startX,
          coords.endX,
          coords.startHeight,
          segments
        );

        ctx.save();

        // Move the context to the icon's center position.
        // Here we translate to `coords.midX, coords.midY`, then we translate back to correctly center the icon.
        ctx.translate(coords.midX, coords.midY);

        const scaleFactor = 1.5;
        ctx.scale(scaleFactor, scaleFactor);

        // Translate back so that the icon is properly centered.
        // In your original code, you translated by -25 on both axes.
        ctx.translate(-12, -25);
        ctx.fillStyle = theme.palette.secondary.dark;

        // ctx.translate(coords.midX - 25, coords.midY - 25); // Move to the icon position
        ctx.fill(iconPath);
        ctx.restore();
        // Draw a small circle at the icon's center (the original coords)
        // The circle has a radius of 2.
        ctx.beginPath();
        ctx.translate(0, -2);
        ctx.arc(coords.midX, coords.midY, 2, 0, Math.PI * 2);
        ctx.fillStyle = theme.palette.secondary.dark; // Adjust the color if necessary.
        ctx.fill();
      }

      ctx.restore();
    };

    // Check if cursor is in a defined rects
    const checkRec = (u) => {
      const mouseCoords = getMouseXY(u);

      const annoCoords = getAnnotationsCoords(u);

      let isOverIcon = false;
      let currentAnno = null;

      for (let index = 0; index < annotation.length; index++) {
        const coordMid = annoCoords[index][1];
        const coordy = annoCoords[index][3];
        const iconPosition = {
          x: coordMid - 10,
          y: coordy - 50,
          width: 20,
          height: 50,
        };
        if (
          mouseCoords[0] >= iconPosition.x &&
          mouseCoords[0] <= iconPosition.x + iconPosition.width &&
          mouseCoords[1] >= iconPosition.y &&
          mouseCoords[1] <= iconPosition.y + iconPosition.height
        ) {
          isOverIcon = true;

          currentAnno = annotation[index];
          break; // No need to check further if we found an icon
        }
      }
      return { currentAnno: currentAnno, isOverIcon: isOverIcon };
    };

    // Based on the zigzack length get the horizontal center
    const getAnnotationsCoords = (u) => {
      const coordList = [];

      const getCenter = (_anno, _u, _amplitude, _index = 0) => {
        const { start_timestamp, end_timestamp } = _anno;
        const midPoint =
          start_timestamp + (end_timestamp - start_timestamp) / 2;

        const distance = 4;
        const startHeight = 35 + _index * distance * _amplitude;
        const startXPos = _u.valToPos(start_timestamp, "x", false);
        const endXPos = _u.valToPos(end_timestamp, "x", false);
        const midXPos = _u.valToPos(midPoint, "x", false); // false: get the position in same format as cursor

        // 3 x values, 1 y value
        return [startXPos, midXPos, endXPos, startHeight];
      };

      for (const [index, anno] of annotation.entries()) {
        const coords = getCenter(anno, u, amplitude, index);
        coordList.push(coords);
      }
      // console.log("hook", `centers`, coordList)
      return coordList;
    };

    // Mouse position Based on mouse event and uPlot, get x/y
    const getMouseXY = (u) => {
      const { left, top } = u.cursor;
      return [left, top];
    };

    return {
      hooks: {
        init: (u) => {
          u.over.tabIndex = -1; // required for key handlers
          u.over.style.outlineWidth = "0"; // prevents yellow input box outline when in focus

          // Change cursor to pointer on mouse move
          u.over.addEventListener("mousemove", (e) => {
            const { isOverIcon, currentAnno } = checkRec(u);

            // Change cursor style based on whether the mouse is over an icon
            u.over.style.cursor = isOverIcon ? "pointer" : "default";
            // console.log("drawAnnotations - init hook", isOverIcon, currentAnno, u.over, mouseX, mouseY)
            // Set up tooltip display based on the hover state
            if (isOverIcon) {
              // Customize the tooltip text as desired; for instance, currentAnno.someProperty
              const rawDescription =
                currentAnno.annotation_data.raw_description;
              const limitedDescription =
                rawDescription.length > 50
                  ? rawDescription.slice(0, 50) + "..."
                  : rawDescription;
              tooltip.textContent = limitedDescription;
              const tooltipWidth = tooltip.offsetWidth;
              tooltip.style.display = "block";
              // Positioning the tooltip above the icon in a central position
              tooltip.style.left = `${e.clientX - tooltipWidth / 2}px`;
              tooltip.style.top = `${e.clientY - 60}px`;
            } else {
              tooltip.style.display = "none";
            }
          });

          u.over.addEventListener("click", (e) => {
            const { isOverIcon, currentAnno } = checkRec(u);
            if (isOverIcon) {
              handleAnnotationComponent(currentAnno, u);
            }
            tooltip.style.display = "none";
          });
        },
        drawClear: drawAnnos,
      },
    };
  }, []);

  useEffect(() => {
    if (!divRef.current || !annotationReady) return;

    // make sure data size fits opts (asynch processes)
    if (
      origData.length !==
      origChartDefs.map((m) => m.points).flat().length + 1
    )
      return;

    const div = divRef.current;
    console.log("recreateing chart");

    let newChart = makeChart({
      chartDefs: origChartDefs,
      enabled: enabled,
      setEnabled: setEnabled,
      origData: origData,
      chartDiv: div,
      opts: opts,
      blockSize: blockSize,
      divK: divK,
      dateWithTimestamps: dateWithTimestamps,
      theme: theme,
      plugins: [drawAnnotations({ annotation: annotation, theme: theme })],
    });

    setPlot(newChart);

    // Cleanup function
    return () => {
      if (newChart) {
        newChart.destroy();
        setPlot(null);
      }
    };
  }, [
    divRef,
    blockSize,
    drawAnnotations,
    dateWithTimestamps,
    divK,
    enabled,
    opts,
    origChartDefs,
    origData,
    theme,
    annotationReady,
    showAnnotation,
    annotation,
  ]);

  const Toolbar = () => {
    const padding = 6;
    const iconSize = blockSize.toolbar.toolbarWidth - 2 * padding;
    const styling = {
      width: iconSize,
      height: iconSize,
    };
    return (
      <Box
        sx={{
          height: "100%",
          width:
            workspaceWidth < split ? "auto" : blockSize.toolbar.toolbarWidth,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          boxSizing: "border-box",
          padding: `${padding}px`,
          paddingBottom: "1rem",
          zIndex: 1,
          // position: 'relative', // Set position to relative for overlay positioning
        }}
      >
        <Stack
          direction={workspaceWidth < split ? "row" : "column"}
          spacing={0.5}
        >
          {/* ADD TOOLTIP */}
          <IconButton
            className="onPaper"
            onClick={() => {}}
            size="small"
            sx={styling}
          >
            <ZoomOutMapIcon />
          </IconButton>
          <IconButton
            className="onPaper"
            onClick={() => setShowAnnotation({})} // empty annotation
            size="small"
            sx={styling}
          >
            <DrawIcon />
          </IconButton>
          <ChartActionMenu charAttr={charAttr} styling={styling} />
          <ChartDescription
            text={
              chartDescriptions.find((f) => f.key === chartSpec)?.description
            }
            blockSize={blockSize}
            styling={styling}
          />

          <IconButton
            className="onPaper"
            onClick={() => exportPng(`${divK}-canvas`, plot)}
            size="small"
            sx={styling}
          >
            <FileDownloadIcon />
          </IconButton>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      {/* Annotations */}
      <ChartBackdrop
        showBackdrop={showAnnotation !== null ? true : false}
        closeOverlay={closeOverlay}
        title={"Annotations"} // TODO translate
      >
        {showAnnotation === null ? null : (
          <Annotation
            origData={origData}
            chartDefs={origChartDefs}
            selectedAnnotation={showAnnotation}
            completeSets={completeSets}
            closeAndUpdate={closeAnnotationOverlayAndUpdate}
          />
        )}
      </ChartBackdrop>

      {/* Fullscreen */}
      <ChartBackdrop
        showBackdrop={showFullScreen}
        closeOverlay={closeOverlay}
        title={"Charts"} // TODO translate
      >
        {"coming soon..."}
      </ChartBackdrop>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: workspaceWidth < split ? "column-reverse" : "row",
          alignItems: "start",
          justifyContent: "left",
        }}
      >
        <div
          id={`${divK}-legend`}
          style={{
            width: blockSize.legend.legendWidth,
            height: blockSize.legend.legendTotalHeight,
          }}
        />

        <div
          id={divK}
          ref={divRef}
          style={{
            width: blockSize.chart.chartWidth,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "left",
          }}
        />
        <Toolbar />
        <canvas id={`${divK}-canvas`} style={{ display: "none" }}></canvas>
      </div>
    </>
  );
};

export default HistorianUPlot;
