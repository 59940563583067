import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Paper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import TaskEditMask from "./TaskEditMask";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { fetchTaskById, patchTaskMetaData } from "../actions/api";

function EditTask() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // Initialize task with empty values.
  const [task, setTask] = useState({
    title: "",
    due_date: "",
    priority: { text: "", api: "", key: "" },
    status: { text: "", api: "", key: "" },
    task_group: { text: "", api: "", key: "" },
    assigned_to: [], // Users currently assigned
    campus_assigned_users: [], // All users available for the campus
    campus: { id: "", name: "" },
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  // Helper to convert a string into an object expected by TaskEditMask.
  const convertField = (value, defaultVal) => {
    const v = value || defaultVal;
    return { text: v, api: v, key: v.toLowerCase() };
  };

  useEffect(() => {
    if (location.state && location.state.task) {
      const tsk = location.state.task;
      setTask({
        title: tsk.title,
        due_date: tsk.due_date,
        priority: convertField(tsk.priority, "Medium"),
        status: convertField(tsk.status, "Open"),
        task_group: convertField(tsk.task_group, "Heating Loops"),
        assigned_to: tsk.assigned_to_details || [],
        campus_assigned_users: tsk.campus_assigned_users || [],
        campus: { id: tsk.campus, name: tsk.campus_name },
      });
      setLoading(false);
    } else {
      console.log(
        "EditTask: No prepopulated task found; fetching task details for ID:",
        id
      );
      const fetchTaskDetails = async () => {
        try {
          const data = await fetchTaskById(id);
          console.log(
            "EditTask: Fetched task details for ID:",
            id,
            "Data:",
            data
          );
          setTask({
            title: data.title,
            due_date: data.due_date,
            priority: convertField(data.priority, "Medium"),
            status: convertField(data.status, "Open"),
            task_group: convertField(data.task_group, "Heating Loops"),
            assigned_to: data.assigned_to_details || [],
            campus_assigned_users: data.campus_assigned_users || [],
            campus: { id: data.campus, name: data.campus_name },
          });
        } catch (error) {
          console.error("EditTask: Error fetching task details:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchTaskDetails();
    }
  }, [id, location.state]);

  const handleUpdateTask = async (e) => {
    e.preventDefault();
    const sanitizedData = {
      title: task.title,
      due_date: task.due_date,
      priority: task.priority.api,
      status: task.status.api,
      task_group: task.task_group.api,
      assigned_to: task.assigned_to.map((user) => user.id),
      campus: task.campus.id,
    };

    try {
      const success = await patchTaskMetaData(id, sanitizedData);
      if (success) {
        setUpdateSuccess(true);
        setTimeout(() => navigate("/tasks"), 2000); // Redirect after success
      }
    } catch (error) {
      console.error("EditTask: Error updating task:", error);
    }
  };

  if (loading) {
    return <Workspace isLoading={true} title={t("Edit Task")} />;
  }

  return (
    <Workspace
      isLoading={false}
      title={
        <Box display="flex" alignItems="center">
          {t("Edit Task")}
          <ModeEditIcon color="primary" sx={{ fontSize: 40, ml: 1 }} />
        </Box>
      }
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: "600px",
          p: 3,
          background: theme.palette.background.default,
        }}
      >
        {updateSuccess && (
          <Box sx={{ color: "success.main", mb: 2 }}>
            {t("Task updated successfully!")}
          </Box>
        )}
        <form onSubmit={handleUpdateTask}>
          <TaskEditMask
            val={task}
            setVal={setTask}
            campuses={[task.campus]} // Prepopulate campus details
            campusUsers={task.campus_assigned_users} // Prepopulate available users
            buttonActionText={t("Update")}
            allowAssign={true}
          />
        </form>
      </Paper>
    </Workspace>
  );
}

export default EditTask;
