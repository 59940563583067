import { DateObject } from "react-multi-date-picker";

const getCurrentQuarter = (date) => {
  if (!(date instanceof DateObject)) {
    console.error("Invalid date. Expected a DateObject instance.");
    return null;
  }

  // Get the current month (1 = January, 12 = December)
  const month = date.month.number;
  const currentYear = date.year;
  // Determine the quarter based on the month
  if (month >= 1 && month <= 3) {
    return {
      quarter: "Q1",
      start: new DateObject({ year: currentYear, month: 1, day: 1 }),
    };
  } else if (month >= 4 && month <= 6) {
    return {
      quarter: "Q2",
      start: new DateObject({ year: currentYear, month: 4, day: 1 }),
    };
  } else if (month >= 7 && month <= 9) {
    return {
      quarter: "Q3",
      start: new DateObject({ year: currentYear, month: 7, day: 1 }),
    };
  } else if (month >= 10 && month <= 12) {
    return {
      quarter: "Q4",
      start: new DateObject({ year: currentYear, month: 10, day: 1 }),
    };
  }

  return null; // Fallback in case of invalid month
};

// Set Date Time objects based on string
export const setDateTimeOnKey = (keyString) => {
  let range;
  const currentDate = new DateObject();
  const currentYear = currentDate.year;
  const weekStart = 1;
  const weekIndex = currentDate.weekDay.index;
  const firstDayOfThisWeek = currentDate.subtract(
    weekIndex - weekStart,
    "days"
  );
  const firstDayOfThisYear = new DateObject().set({
    year: currentYear,
    month: 1,
    date: 1,
  });
  const quarterInfo = getCurrentQuarter(currentDate);

  switch (keyString) {
    case "this_day":
      range = [new DateObject().subtract(0, "days"), new DateObject()];
      break;
    case "this_week":
      range = [
        new DateObject(firstDayOfThisWeek),
        new DateObject(firstDayOfThisWeek).add(6, "days"),
      ];
      break;
    case "this_month":
      range = [new DateObject().toFirstOfMonth(), new DateObject()];
      break;
    case "this_quarter":
      range = [new DateObject(quarterInfo.start), new DateObject(currentDate)];
      break;
    case "this_year":
      range = [new DateObject(firstDayOfThisYear), new DateObject()];
      break;
    case "last_day":
      range = [
        new DateObject().subtract(1, "days"),
        new DateObject().subtract(1, "days"),
      ];
      break;
    case "last_week":
      let lastMonday = firstDayOfThisWeek.subtract(7, "days");
      range = [
        new DateObject(lastMonday),
        new DateObject(lastMonday).add(6, "days"),
      ];
      break;
    case "last_month":
      range = [
        new DateObject().subtract(1, "month").toFirstOfMonth(),
        new DateObject().subtract(1, "month").toLastOfMonth(),
      ];
      break;
    case "last_quarter": // TODO is wrong
      const start = new DateObject(quarterInfo.start).subtract(3, "months");
      range = [
        new DateObject(start),
        new DateObject(quarterInfo.start.subtract(1, "days")),
      ];
      break;
    case "last_year":
      range = [
        new DateObject(firstDayOfThisYear).subtract(1, "year"),
        new DateObject(firstDayOfThisYear).subtract(1, "days"),
      ];
      break;
    default:
      range = [
        new DateObject(firstDayOfThisWeek),
        new DateObject(firstDayOfThisWeek).add(6, "days"),
      ];
  }

  return {
    startDate: range[0].format("YYYY-MM-DD"),
    startTime: range[0].format("00:00"),
    startDateTime: range[0].format("YYYY-MM-DD 00:00:00"),
    startDateTimeObject: range[0],
    endDate: range[1].format("YYYY-MM-DD"),
    endTime: range[1].format("23:59"),
    endDateTime: range[1].format("YYYY-MM-DD 23:59:59"),
    endDateTimeObject: range[1],
  };
};
