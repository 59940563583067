import React, { useEffect, useState, useRef, useCallback } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import {
  // Use your original chart sizing function from chartFuncs.js
  getChartBlockSizes,
  convertLocalValToUtcEpoch,
} from "./chartFuncs";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  timeZoneData,
  syncCursers,
  formatDates,
  formatAxes,
  defineYSeries,
  makeAnnotationChart,
} from "./uplotFuncs";
import { useNotification } from "../Generic/NotificationContext";
import {
  postChartAnnotations,
  updateChartAnnotations,
  deleteChartAnnotations,
} from "../actions/api";
import "./Uplot.css";
import { useTheme } from "@mui/material";
import { useTranslation, Translation } from "react-i18next";

/**
 * Default shape of annotation data
 */
const defaultAnnotation = {
  annotation_data: {
    start_timestamp: null,
    end_timestamp: null,
    raw_description: "",
    ids: [], // which series (points) to attach annotation to
  },
};

const Annotation = ({
  // from parent
  plot,
  origData,
  chartDefs,
  selectedAnnotation,
  completeSets, // contains { charts, dataSets, selectedCampus }
  closeAndUpdate, // callback to close and refresh
}) => {
  const { charts, dataSets, selectedCampus } = completeSets;
  const { campusId, campusTimeZone } = selectedCampus;

  const { addError } = useNotification();
  const theme = useTheme();
  const { t } = useTranslation();

  // local states
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [localPlots, setLocalPlots] = useState([]);
  const [blockSize, setBlockSize] = useState([]);
  const [annotation, setAnnotation] = useState(defaultAnnotation);
  const [opts, setOpts] = useState([]);

  // references for chart containers
  const chartRefs = useRef([]);
  const idContainer = "annotationChartContainer";

  // media queries for responsive sizing
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isLargeTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );

  /**
   * Simple debounce to avoid frequent re-renders on resize
   */
  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  /**
   * Compute the chart sizing for each chart
   */
  const handleResize = useCallback(() => {
    if (!charts || charts.length === 0) return;

    try {
      // Sort by index if needed
      const sortedCharts = [...charts].sort((a, b) => a.index - b.index);

      // 1) For each chart, compute sizes using getChartBlockSizes
      const sizes = sortedCharts.map((chart) => {
        const rawSizes = getChartBlockSizes(
          idContainer,
          0, // no drawer in the annotation dialog
          chart.dataSeries,
          [isMobile, isTablet, isLargeTablet],
          5 // toolNumber
        );

        // 2) Optionally tweak the returned sizes for annotation usage
        const modSizes = {
          ...rawSizes,
          chart: {
            ...rawSizes.chart,
            // add extra horizontal space if needed
            chartWidth: rawSizes.chart.chartWidth + rawSizes.parent.padding * 2,
          },
          legend: {
            ...rawSizes.legend,
            // combine legend + toolbar widths for annotations
            legendWidth:
              rawSizes.legend.legendWidth + rawSizes.toolbar.toolbarWidth,
            legendWidthLabel:
              rawSizes.legend.legendWidthLabel + rawSizes.toolbar.toolbarWidth,
          },
        };

        return modSizes;
      });

      // 3) Build the initial `opts` for each chart, using your uPlot helpers
      const newOpts = sortedCharts.map((chart, index) => ({
        id: `chart-${index}-annotation`,
        width: sizes[index].chart.chartWidth,
        height: sizes[index].chart.chartHeight,
        padding: [10, 0, 10, 10], // top,right,bottom,left
        legend: { show: true },
        series: [
          // The x-axis / time series
          {
            label: t("TIME"),
            value: (self, rawValue) => {
              const date = new Date(rawValue * 1000);
              const userLocale =
                navigator.language || navigator.userLanguage || "en-US";
              return date.toLocaleString(userLocale);
            },
          },
          // Add your Y-series definitions
          ...defineYSeries(chart.dataSeries, theme),
        ],
        ...timeZoneData(campusTimeZone),
        ...syncCursers("syncKey-local"),
        ...formatDates(),
        ...formatAxes(chart.dataSeries, theme),
      }));

      setBlockSize(sizes);
      setOpts(newOpts);
    } catch (err) {
      console.error("handleResize => error computing sizes:", err);
    }
  }, [charts, isMobile, isTablet, isLargeTablet, t, theme, campusTimeZone]);

  // Attach a resize listener
  useEffect(() => {
    handleResize();
    const debouncedResize = debounce(handleResize, 100);
    window.addEventListener("resize", debouncedResize);
    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, [handleResize]);

  /**
   * Load selectedAnnotation into local state if any
   */
  useEffect(() => {
    if (selectedAnnotation && Object.keys(selectedAnnotation).length > 0) {
      setAnnotation((prev) => ({
        ...prev,
        annotation_data: {
          ...prev.annotation_data,
          start_timestamp: selectedAnnotation.annotation_data.start_timestamp,
          end_timestamp: selectedAnnotation.annotation_data.end_timestamp,
          raw_description: selectedAnnotation.annotation_data.raw_description,
          ids: selectedAnnotation.annotation_data.ids,
        },
      }));
    }
  }, [selectedAnnotation]);

  /**
   * Build the annotation charts once we have blockSize + opts
   */
  useEffect(() => {
    if (!charts || charts.length === 0 || blockSize.length === 0) return;

    const newPlots = [];
    charts.forEach((chart, index) => {
      const chartDiv = chartRefs.current[index];
      if (!chartDiv || chart.dataSeries.length === 0) return;

      const builtPlot = makeAnnotationChart({
        chartDefs: chart.dataSeries,
        enabled: true,
        setEnabled: true,
        origData: dataSets[index],
        chartDiv,
        opts: opts[index],
        blockSize: blockSize[index],
        divK: `${idContainer}-${index}`,
        theme,
        setAnnotation,
        selectedAnnotation,
        tz: campusTimeZone,
        plugins: [], // if you have any custom plugins to pass
      });

      newPlots[index] = builtPlot;
    });

    // store locally
    setLocalPlots(newPlots);

    // cleanup when unmounting
    return () => {
      newPlots.forEach((plot) => {
        if (plot) plot.destroy();
      });
      setLocalPlots([]);
    };
  }, [
    charts,
    dataSets,
    opts,
    blockSize,
    theme,
    campusTimeZone,
    selectedAnnotation,
  ]);

  /**
   * Toggle a series in the annotation's `ids` array
   */
  const handleCheckbox = (value) => () => {
    const currentIndex = annotation.annotation_data.ids.indexOf(value);
    let newChecked = [...annotation.annotation_data.ids];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAnnotation((prev) => ({
      ...prev,
      annotation_data: {
        ...prev.annotation_data,
        ids: newChecked,
      },
    }));
  };

  /**
   * Handle Delete Confirmation
   */
  const handleClickOpen = () => setOpenDeleteDialog(true);
  const handleClose = () => setOpenDeleteDialog(false);

  /**
   * Validate annotation data
   */
  const completeCheck = () => {
    const { start_timestamp, end_timestamp, raw_description, ids } =
      annotation.annotation_data;

    if (start_timestamp && end_timestamp && raw_description && ids.length > 0) {
      return true;
    }
    addError("Data not complete. Check all entries.");
    return false;
  };

  /**
   * Create / Update annotation
   */
  const handleSubmit = async () => {
    if (!completeCheck()) return;

    let error = false;

    // 1) Remove leading '@' from IDs
    const idList = annotation.annotation_data.ids.map((item) =>
      item.startsWith("@") ? item.substring(1) : item
    );

    // 2) Convert local to UTC
    const modAnnotation = {
      ...annotation,
      annotation_data: {
        ...annotation.annotation_data,
        ids: idList,
        end_timestamp: convertLocalValToUtcEpoch(
          annotation.annotation_data.end_timestamp,
          campusTimeZone
        ),
        start_timestamp: convertLocalValToUtcEpoch(
          annotation.annotation_data.start_timestamp,
          campusTimeZone
        ),
      },
    };

    // 3) Save to DB
    const dataStr = JSON.stringify(modAnnotation);

    if (selectedAnnotation && Object.keys(selectedAnnotation).length > 0) {
      // Update existing
      const annotation_id = selectedAnnotation.annotation_id;
      const data = await updateChartAnnotations(
        campusId,
        annotation_id,
        dataStr
      );
      if (!data.success) {
        addError("Updating annotation failed. Try again later.");
        error = true;
      }
    } else {
      // Create new
      const data = await postChartAnnotations(campusId, dataStr);
      if (!data.success) {
        addError("Creating annotation failed. Try again later.");
        error = true;
      }
    }

    if (!error) {
      setAnnotation(defaultAnnotation);
      closeAndUpdate();
    }
  };

  /**
   * Delete annotation
   */
  const handleDelete = async () => {
    setOpenDeleteDialog(false);
    if (selectedAnnotation && Object.keys(selectedAnnotation).length > 0) {
      const annotation_id = selectedAnnotation.annotation_id;
      const data = await deleteChartAnnotations(campusId, annotation_id);
      if (!data.success) {
        addError("Deleting failed. Try again later.");
      }
    } else {
      console.error("No 'annotation_id' found. Skip delete.");
    }
    setAnnotation(defaultAnnotation);
    closeAndUpdate();
  };

  /**
   * Convert epoch to local DateTime
   */
  const toDateTime = (epoch) => {
    const date = new Date(epoch * 1000);
    const formatter = new Intl.DateTimeFormat("de-CH", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    return formatter.format(date);
  };

  return (
    // OUTER WRAPPER with vertical scroll + max height
    <div
      style={{
        // Give yourself some vertical space for scrolling
        maxHeight: "calc(100vh - 150px)",
        overflowY: "auto",
        padding: "16px",
      }}
    >
      {/* ------------------- Series & Description Section ------------------- */}
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ width: "450px", maxHeight: "100px" }}>
          <Typography variant="h6" component="h2" gutterBottom>
            {t("SELECT_RELATED_SERIES")}
          </Typography>

          <FormGroup
            sx={{
              width: "450px",
              paddingTop: "0px",
              overflowY: "auto",
              maxHeight: "200px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
          >
            {charts.map((chart) =>
              chart.dataSeries.map((series) =>
                series.points.map((pt, ind) => {
                  const ptWithoutAt = pt.replace("@", "");
                  return (
                    <FormControlLabel
                      key={pt}
                      control={
                        <Checkbox
                          edge="end"
                          onChange={handleCheckbox(ptWithoutAt)}
                          checked={annotation.annotation_data.ids.includes(
                            ptWithoutAt
                          )}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          marginLeft: "10px",
                        },
                        whiteSpace: "nowrap",
                      }}
                      label={series.navName[ind]}
                    />
                  );
                })
              )
            )}
          </FormGroup>
        </div>

        <div style={{ flex: 1, paddingLeft: "25px", height: "250px" }}>
          <Typography variant="h6" component="h2" gutterBottom>
            {t("ANNOTATION_DESC")}
          </Typography>
          <TextField
            id="multiline-annotations"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                alignItems: "flex-start",
                minHeight: "200px",
              },
            }}
            style={{ minHeight: "200px" }}
            onChange={(event) =>
              setAnnotation((prev) => ({
                ...prev,
                annotation_data: {
                  ...prev.annotation_data,
                  raw_description: event.target.value,
                },
              }))
            }
            value={annotation.annotation_data.raw_description}
            multiline
            maxRows={10}
          />
        </div>
      </div>

      {/* ------------------- Charts Section ------------------- */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          paddingTop: "2rem",
        }}
      >
        <Typography variant="h6" component="div">
          {annotation.annotation_data.start_timestamp === null ||
          annotation.annotation_data.end_timestamp === null
            ? t("CLICK_TO_SELECT_TIME_RANGE")
            : `${t("SELECTED_TIME_RANGE")}: ${toDateTime(
                annotation.annotation_data.start_timestamp
              )} - ${toDateTime(annotation.annotation_data.end_timestamp)}`}
        </Typography>

        <div
          id={idContainer}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "16px",
          }}
        >
          {blockSize.length > 0 &&
            charts.map((chart, index) => (
              <div
                id={`${idContainer}-${index}`}
                key={`${idContainer}-${index}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "16px",
                }}
              >
                {/* The left legend portion */}
                <div
                  id={`${idContainer}-${index}-legend`}
                  style={{
                    width: blockSize[index].legend.legendWidth,
                    height: blockSize[index].legend.legendTotalHeight,
                  }}
                />
                {/* The chart portion */}
                <div
                  key={`chart-section-${index}`}
                  ref={(el) => (chartRefs.current[index] = el)}
                  style={{
                    width: blockSize[index].chart.chartWidth,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "left",
                  }}
                />
              </div>
            ))}
        </div>

        {/* ------------------- Action Buttons (Delete & Save) ------------------- */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          {/* Delete Button -> Opens confirmation */}
          <Button
            className="workspace"
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<CloseIcon />}
            sx={{ marginTop: 2, marginRight: 0, marginBottom: 0 }}
          >
            <Translation>{() => <div>{t("DELETE")}</div>}</Translation>
          </Button>

          {/* Save / Submit Button */}
          <Button
            className="workspace"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ marginLeft: 2, marginTop: 2, marginBottom: 0 }}
            onClick={handleSubmit}
          >
            <Translation>{() => <div>{t("SAVE_ANNOTATION")}</div>}</Translation>
          </Button>
        </div>

        {/* ------------------- Delete Confirmation Dialog ------------------- */}
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("DELETE_ANNOTATION")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("DELETE_CONFIRMATION")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="workspace"
                variant="contained"
                onClick={handleClose}
                sx={{ marginTop: 0, marginRight: 2, marginBottom: 0 }}
              >
                <Translation>{() => <div>{t("NO")}</div>}</Translation>
              </Button>
              <Button
                className="workspace"
                variant="contained"
                sx={{ marginTop: 0, marginRight: 0, marginBottom: 0 }}
                onClick={handleDelete}
              >
                <Translation>{() => <div>{t("YES")}</div>}</Translation>
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Annotation;
