import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuItem } from "@mui/material";
import FunctionsIcon from "@mui/icons-material/Functions";

const ChartActionMenu = ({ charAttr, styling = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionButtonClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleAction = (action) => {
    console.log("Action selected:", action);
    handleClose(); // Close the menu after selecting an action
  };

  return (
    charAttr.manualActions?.length > 0 && (
      <>
        {/* Action Button */}
        <IconButton
          className="onPaper"
          onClick={handleActionButtonClick}
          size="small"
          sx={styling || { padding: 1 }} // Fallback styling
        >
          <FunctionsIcon />
        </IconButton>

        {/* Action Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {charAttr.manualActions.map((action, index) => (
            <MenuItem
              key={`key-${index}-${action.dis}`}
              onClick={() => handleAction(action)}
            >
              {action.dis}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};

ChartActionMenu.propTypes = {
  charAttr: PropTypes.shape({
    manualActions: PropTypes.arrayOf(
      PropTypes.shape({
        dis: PropTypes.string.isRequired, // Display text for the action
      })
    ),
  }).isRequired,
  styling: PropTypes.object, // Optional styling for the IconButton
};

export default ChartActionMenu;
