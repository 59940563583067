import React, { useState, useEffect, useCallback, useMemo } from "react";
import { DateObject } from "react-multi-date-picker";
import { Stack, useMediaQuery, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import RangePopover from "./RangePopover";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// Select date range
// Format object handler: { startDate: null, startTime: null, startDateTime: null, endDate: null, endTime: null, endDateTime: null }
// Fires only if: calendar handling finished, forward button, backward button
const BaseElement = ({ objectHandler, currentStep }) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const { t } = useTranslation();
  const [values, setValues] = useState(() => {
    // str (haystack id)
    //startDate and endDate
    const saved = localStorage.getItem("selectedDates");
    if (saved === null || saved.length === 0) {
      // Default: last seven days
      return [new DateObject().subtract(7, "days"), new DateObject()];
    } else {
      // Convert to value
      const obj = JSON.parse(saved);
      const dateStart = new DateObject(obj.startDateTime);
      const dateEnd = new DateObject(obj.endDateTime);
      return [dateStart, dateEnd];
    }
  });
  const [selectedRange, setSelectedRange] = useState(null);

  const debug = false;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );

  // Modify object
  const spanOnTheFly = useCallback(
    (vals) => {
      if (vals.length === 1 && vals[0] instanceof DateObject) {
        return {
          startDate: vals[0].format(t("RANGE_PICKER_FORMAT")),
          startTime: vals[0].format("00:00"),
          startDateTime: vals[0].format("YYYY-MM-DD 00:00:00"), // 'YYYY-MM-DD HH:MM:SS'
          startDateTimeObject: vals[0],
          endDate: vals[0].format(t("RANGE_PICKER_FORMAT")),
          endTime: vals[0].format("23:59"),
          endDateTime: vals[0].format("YYYY-MM-DD 23:59:59"),
          endDateTimeObject: vals[1],
        };
      }
      if (vals.length === 2 && vals[0] instanceof DateObject) {
        return {
          startDate: vals[0].format(t("RANGE_PICKER_FORMAT")),
          startTime: vals[0].format("00:00"),
          startDateTime: vals[0].format("YYYY-MM-DD 00:00:00"), // 'YYYY-MM-DD HH:MM:SS'
          startDateTimeObject: vals[0],
          endDate: vals[1].format(t("RANGE_PICKER_FORMAT")),
          endTime: vals[1].format("23:59"),
          endDateTime: vals[1].format("YYYY-MM-DD 23:59:59"),
          endDateTimeObject: vals[1],
        };
      }
      // default
      return {
        startDate: null,
        startTime: null,
        startDateTime: null,
        startDateTimeObject: null,
        endDate: null,
        endTime: null,
        endDateTime: null,
        endDateTimeObject: null,
      };
    },
    [t]
  );

  // Update parent
  useEffect(() => {
    // return only, if values has 2 entries (is valid) and popover is closed
    if (values.length === 2 && !popoverIsOpen) {
      const spanObj = spanOnTheFly(values);
      if (debug) console.log("[BaseElement] Return value: ", spanObj);
      localStorage.setItem("selectedChartSpan", JSON.stringify(spanObj));
      objectHandler(spanObj);
    }
  }, [objectHandler, values, spanOnTheFly, debug, popoverIsOpen]);

  // Updater for favorite, requires allCampuses loaded
  // trigger whenever refresh changes,
  useEffect(() => {
    if (currentStep === 1) {
      const start = localStorage.getItem("chart_favorite_startDateTime");
      if (start) {
        const end = localStorage.getItem("chart_favorite_endDateTime");
        const startParsed = JSON.parse(start);
        const endParsed = JSON.parse(end);
        // Make objects
        const startDate = new DateObject(startParsed);
        const endDate = new DateObject(endParsed);

        // set values
        setValues([startDate, endDate]);

        localStorage.removeItem("chart_favorite_startDateTime");
        localStorage.removeItem("chart_favorite_endDateTime");
      }
    }
  }, [currentStep]);

  // Function for time steps
  const calcDelta = useCallback(
    (vals) => {
      // Consider selected range
      if (
        selectedRange === null ||
        (selectedRange && selectedRange.key === "THIS_DAY") ||
        (selectedRange && selectedRange.key === "LAST_DAY")
      ) {
        if (vals.length === 1 && vals[0] instanceof DateObject) {
          return 1;
        } else if (
          vals.length === 2 &&
          vals[0] instanceof DateObject &&
          vals[1] instanceof DateObject
        ) {
          const diffInMilliseconds =
            vals[1].toDate().getTime() - vals[0].toDate().getTime();
          return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
        } else {
          // Default case: return today's day
          return 7;
        }
      } else if (
        (selectedRange && selectedRange.key === "THIS_WEEK") ||
        (selectedRange && selectedRange.key === "LAST_WEEK")
      ) {
        const diffInMilliseconds =
          vals[1].toDate().getTime() - vals[0].toDate().getTime();
        return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
      } else if (
        (selectedRange && selectedRange.key === "THIS_MONTH") ||
        (selectedRange && selectedRange.key === "LAST_MONTH")
      ) {
        return 1;
      } else if (
        (selectedRange && selectedRange.key === "THIS_QUARTER") ||
        (selectedRange && selectedRange.key === "LAST_QUARTER")
      ) {
        return 3;
      } else if (
        (selectedRange && selectedRange.key === "THIS_YEAR") ||
        (selectedRange && selectedRange.key === "LAST_YEAR")
      ) {
        return 1;
      } else {
        // TODO
        console.error("Not implemented");
      }
    },
    [selectedRange] // Dependency array
  );

  const handleTimestep = useCallback(
    (dir) => {
      if (!Array.isArray(values) || values.length !== 2) {
        console.error(
          "Invalid values array. Expected an array with two DateObject instances.",
          values,
          dir
        );
        return;
      }

      if (!["back", "forward"].includes(dir)) {
        console.error("Invalid direction. Expected 'back' or 'forward'.");
        return;
      }

      const [val1, val2] = values;

      if (!(val1 instanceof DateObject) || !(val2 instanceof DateObject)) {
        console.error("Invalid values. Expected DateObject instances.");
        return;
      }

      // Create new instances of DateObject
      const newVal1 = new DateObject(val1);
      const newVal2 = new DateObject(val2);

      try {
        // console.log("range", calcDelta(values,dir))
        const delta = calcDelta([newVal1, newVal2]);
        if (typeof delta !== "number" || isNaN(delta)) {
          console.error("Invalid delta value. Expected a valid number.");
          return;
        }

        const adjustment = dir === "back" ? -delta : delta;

        let adjustedVal1 = newVal1;
        let adjustedVal2 = newVal2;

        if (
          (selectedRange && selectedRange.key === "THIS_MONTH") ||
          (selectedRange && selectedRange.key === "LAST_MONTH")
        ) {
          adjustedVal1 = newVal1.add(adjustment, "months").toFirstOfMonth();
          adjustedVal2 = newVal2.add(adjustment, "months").toLastOfMonth();
        } else if (
          (selectedRange && selectedRange.key === "THIS_QUARTER") ||
          (selectedRange && selectedRange.key === "LAST_QUARTER")
        ) {
          adjustedVal1 = newVal1.add(adjustment, "months").toFirstOfMonth();
          adjustedVal2 = newVal2.add(adjustment, "months").toLastOfMonth();
        } else if (
          (selectedRange && selectedRange.key === "THIS_YEAR") ||
          (selectedRange && selectedRange.key === "LAST_YEAR")
        ) {
          adjustedVal1 = newVal1.add(adjustment, "year").toFirstOfYear();
          adjustedVal2 = newVal2.add(adjustment, "year").toLastOfYear();
        } else {
          // standard
          adjustedVal1 = newVal1.add(adjustment, "days");
          adjustedVal2 = newVal2.add(adjustment, "days");
        }
        if (debug) console.log("Set values: ", adjustedVal1, adjustedVal2);
        setValues([adjustedVal1, adjustedVal2]);
      } catch (error) {
        console.error("Error in handleTimestep:", error);
      }
    },
    [values, calcDelta, selectedRange, debug]
  );

  const memoizedShowRangeStr = useMemo(() => {
    const range = spanOnTheFly(values);
    return range.startDate
      ? `${range.startDate} - ${range.endDate}`
      : t("SELECT_DATE_RANGE");
  }, [values, spanOnTheFly, t]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        style={{
          marginLeft: isMobile ? "0px" : isTablet ? "0px" : "20px",
          marginTop: isMobile ? "10px" : isTablet ? "10px" : "0px",
        }}
      >
        <IconButton aria-label="menu" onClick={() => handleTimestep("back")}>
          <ChevronLeftIcon />
        </IconButton>
        <RangePopover
          openStateHandler={setPopoverIsOpen}
          updateValues={setValues}
          actualValues={values}
          rangeHandler={setSelectedRange} // today, yesterday....
          actualRange={selectedRange}
          dateString={memoizedShowRangeStr}
        />
        <IconButton
          color="primary"
          aria-label="directions"
          onClick={() => handleTimestep("forward")}
        >
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </>
  );
};

export default BaseElement;
