import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, Stack, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import ChartTargetFilter from "./ChartTargetFilter";
import ChartButtons from "./ChartButtons";
import ChartGraphWorkspace from "./ChartGraphWorkspace";
import { useNotification } from "../Generic/NotificationContext";
import {
  fetchCustomerAccessibleCampus,
  fetchUserAccessibleCampuses,
  fetchChartLinkData,
} from "../actions/api";
import { hackDemo } from "./chartFuncs";

const Charts = () => {
  const debug = false;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );

  const { t } = useTranslation();
  const { addError } = useNotification();

  // Read link if available
  const location = useLocation();

  // --------------------------
  // State for campuses, loading flag and charts
  // --------------------------
  const [allCampuses, setAllCampuses] = useState([]);
  const [isLoadingCampuses, setIsLoadingCampuses] = useState(true);
  const [state, setState] = useState({
    selectedCampus: {
      customerID: null,
      campusId: null,
      campusName: null,
      campusHaystackId: null,
      campusTimeZone: null,
    },
    selectedCategory: {
      chartGroupName: null,
      subGroups: [],
      chartReset: false,
      chartWait: true,
    },
    favorite: {
      selectedCampusId: null,
      selectedChartView: null,
      selectedChartDef: null,
      selectedTargetId: null,
      startDateTime: null,
      endDateTime: null,
    },
    chartObject: {
      charts: [],
      chartViewData: {},
      selectedChartView: null,
      selectedTargetId: null,
    },
    dateRangeSelection: {
      startDate: null,
      startTime: null,
      startDateTime: null,
      endDate: null,
      endTime: null,
      endDateTime: null,
    },
    currentStep: 0, // 0: do nothing, 1: update button file, 2: update target
    showChart: false,
  });

  // --------------------------
  // Fetch user-accessible campuses (for link validation)
  // --------------------------
  const fetchCampuses = useCallback(async () => {
    try {
      const data = await fetchUserAccessibleCampuses();
      if (data?.length > 0) {
        return data;
      } else {
        addError(t("No campuses available for this user."));
        return null;
      }
    } catch (error) {
      addError(t("Error fetching campuses data."));
      return null;
    }
  }, [addError, t]);

  // --------------------------
  // Fetch link data if available and update localStorage accordingly
  // --------------------------
  const fetchLinkData = useCallback(
    async (hashCode) => {
      try {
        const response = await fetchChartLinkData(hashCode);

        // same code as in FavoriteDrawer.js
        if (response?.success) {
          const { page_data } = response.data;
          // For charts: continue only, if all values available
          if (
            page_data.type === "chart" &&
            page_data.selectedCampusId &&
            page_data.selectedChartView &&
            page_data.selectedChartDef &&
            page_data.selectedTargetId &&
            page_data.startDateTime &&
            page_data.endDateTime
          ) {
            // Remove selectedCampus from storage, otherwise we mix up selectedCampus and chartHeaderObject
            // It would be better, to completely separate the information, but this would mean to update all functions depending on
            // chartHeaderObject

            // get campus id
            const campusId = page_data.selectedCampusId;

            // compare with accessible campuses
            const campuses = await fetchCampuses();
            // exists?
            const campusExists = campuses.some(
              (campus) => campus.id === campusId
            );
            if (campusExists) {
              // Update localStorage
              localStorage.setItem(
                "chart_favorite_type",
                JSON.stringify("chart")
              );
              localStorage.setItem(
                "chart_favorite_selectedCampusId",
                JSON.stringify(campusId)
              );
              localStorage.setItem(
                "chart_favorite_selectedChartView",
                JSON.stringify(page_data.selectedChartView)
              );
              localStorage.setItem(
                "chart_favorite_selectedChartDef",
                JSON.stringify(page_data.selectedChartDef)
              );
              localStorage.setItem(
                "chart_favorite_selectedTargetId",
                JSON.stringify(page_data.selectedTargetId)
              );
              localStorage.setItem(
                "chart_favorite_startDateTime",
                JSON.stringify(page_data.startDateTime)
              );
              localStorage.setItem(
                "chart_favorite_endDateTime",
                JSON.stringify(page_data.endDateTime)
              );
              const storageEvent = new Event("localStorageUpdate");
              window.dispatchEvent(storageEvent);
            } else {
              addError(
                "The link refers to a campus without access rights or it does not exist."
              );
            }
          }
        } else {
          addError("Failed to retrieve the link.");
        }
        if (debug) console.log("Link request response:", response);
      } catch (error) {
        addError("Error fetching link data.");
      }
    },
    [addError, debug, fetchCampuses]
  );

  // --------------------------
  // Listen for storage updates (from link processing)
  // --------------------------
  useEffect(() => {
    const handleStorageChange = () => {
      if (JSON.parse(localStorage.getItem("chart_favorite_type")) === "chart") {
        const target = localStorage.getItem("chart_favorite_selectedTargetId");
        const targetId = JSON.parse(target);
        const view = localStorage.getItem("chart_favorite_selectedChartView");
        const viewParsed = JSON.parse(view);
        // Start the refresh process => currentStep = 1
        setState((prevState) => ({
          ...prevState,
          selectedCampus: {
            ...prevState.selectedCampus,
            // campusId: favCampId,
          },
          selectedTargetId: targetId,
          chartObject: {
            ...prevState.chartObject,
            selectedTargetId: targetId,
            selectedChartView: viewParsed,
            selectedChartDef: viewParsed,
          },
          favorite: {
            ...prevState.favorite,
            selectedTargetId: targetId,
          },
          showChart: false,
          currentStep: 1,
        }));
        localStorage.removeItem("chart_favorite_selectedChartDef");
        localStorage.removeItem("chart_favorite_type");
        localStorage.removeItem("chart_favorite_selectedTargetId");
      }
    };

    // Add event listener for storage changes
    window.addEventListener("localStorageUpdate", handleStorageChange);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("localStorageUpdate", handleStorageChange);
    };
  }, []);

  // --------------------------
  // Handle URL search parameter for link
  // --------------------------
  useEffect(() => {
    // Create URLSearchParams object from the URL's search parameters
    const queryParams = new URLSearchParams(location.search);
    const currentLink = queryParams.get("link");
    // If a link exists, fetch data
    if (currentLink) {
      const fetchData = async () => {
        fetchLinkData(currentLink);
      };
      queryParams.delete("link");
      window.location.href = `#/charts`;
      fetchData();
    }
  }, [fetchLinkData, location]);

  // --------------------------
  // Fetch campuses from all customers (updated for backend without pagination)
  // --------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerDataResponse = await fetchCustomerAccessibleCampus();
        // Handle both cases: if response is an array or an object with a results property.
        let campusesList = [];
        if (Array.isArray(customerDataResponse)) {
          campusesList = customerDataResponse;
        } else if (
          customerDataResponse &&
          Array.isArray(customerDataResponse.results)
        ) {
          campusesList = customerDataResponse.results;
        }
        if (campusesList.length > 0) {
          const campuses = [];
          campusesList.forEach((customerData) => {
            const customerID = customerData.customer_id;
            const customerCampuses = customerData.campuses || [];
            customerCampuses.forEach((campus) => {
              campus.customerID = customerID;
            });
            campuses.push(...customerCampuses);
          });
          if (debug) console.log("Collected campuses:", campuses);
          if (campuses.length > 0) {
            const processedCampuses = campuses.map((campus) => ({
              ...campus,
              label: campus.name || "Unnamed Campus",
              campus_haystack_model_id: hackDemo(
                campus.campus_haystack_model_id
              ),
              sites: Array.isArray(campus.sites)
                ? campus.sites.map((site) => ({
                    ...site,
                    campus_id: campus.id,
                  }))
                : [],
            }));
            // if (debug) console.log("Processed campuses:", processedCampuses);
            setAllCampuses(processedCampuses);
          } else {
            console.error(t("No campuses available for this user."));
          }
        } else {
          console.error(t("No customer data available."));
        }
      } catch (error) {
        console.error(t("Error fetching customer data."), error);
      } finally {
        setIsLoadingCampuses(false);
      }
    };
    fetchData();
  }, [t, debug]);

  // --------------------------
  // Validate the selected campus: if it’s not in allCampuses, clear the selection.
  // --------------------------
  useEffect(() => {
    if (
      allCampuses.length > 0 &&
      state.selectedCampus &&
      state.selectedCampus.campusId
    ) {
      const exists = allCampuses.some(
        (campus) => campus.id === state.selectedCampus.campusId
      );
      if (!exists) {
        console.log(
          "Selected campus",
          state.selectedCampus,
          "is not in the accessible list. Clearing selection."
        );
        setState((prevState) => ({
          ...prevState,
          selectedCampus: {
            customerID: null,
            campusId: null,
            campusName: null,
            campusHaystackId: null,
            campusTimeZone: null,
          },
        }));
      }
    }
  }, [allCampuses, state.selectedCampus]);

  // --------------------------
  // Other handlers remain unchanged.
  // --------------------------
  const campusAndCategoryHandler = useCallback(
    (data) => {
      if (debug) console.log("campusAndCategoryHandler", data);
      if (data.currentStep === 0) {
        setState((prevState) => ({
          ...prevState,
          selectedCampus: data.campus,
          favorite: {
            ...prevState.favorite,
            selectedCampusId: data.campus.campusId,
          },
          selectedCategory: data.selectedCategory,
          chartObject: {
            ...prevState.chartObject,
            treeData: data.treeData,
            allTargets: data.allTargets,
            chartViewData: {},
            selectedChartView: null,
            selectedTargetId: null,
            charts: [],
          },
          currentStep: data.currentStep,
          showChart: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          selectedCampus: data.campus,
          favorite: {
            ...prevState.favorite,
            selectedCampusId: data.campus.campusId,
          },
          selectedCategory: data.selectedCategory,
          currentStep: data.currentStep,
          chartObject: {
            ...prevState.chartObject,
            treeData: data.treeData,
            allTargets: data.allTargets,
          },
          showChart: false,
        }));
      }
    },
    [debug]
  );

  const resetChartObject = useCallback(() => {
    if (debug) console.log("resetChartObject");
    setState((prevState) => ({
      ...prevState,
      selectedCategory: {
        ...prevState.selectedCategory,
        reset: true,
      },
      selectedCampus: {
        customerID: null,
        campusId: null,
        campusName: null,
        campusHaystackId: null,
        campusTimeZone: null,
      },
      chartObject: {
        ...prevState.chartObject,
        chartViewData: {},
        selectedChartView: null,
        selectedTargetId: null,
        charts: [],
      },
      currentStep: 0,
      showChart: false,
    }));
  }, [debug]);

  const categoryHandler = useCallback(
    (category) => {
      if (debug) console.log("categoryHandler");
      setState((prevState) => ({
        ...prevState,
        selectedCategory: category,
        chartObject: {
          ...prevState.chartObject,
          selectedTargetId: null,
          selectedChartView: null,
          charts: [],
          chartViewData: {},
        },
        showChart: false,
      }));
    },
    [debug]
  );

  const dateRangeHandler = useCallback(
    (dateRange) => {
      if (debug) console.log("dateRangeHandler");
      setState((prevState) => ({
        ...prevState,
        dateRangeSelection: dateRange,
        favorite: {
          ...prevState.favorite,
          startDateTime: dateRange.startDateTime,
          endDateTime: dateRange.endDateTime,
        },
      }));
    },
    [debug]
  );

  const chartObjectHandler = useCallback(
    (val) => {
      if (debug) console.log("chartObjectHandler", val);
      setState((prevState) => ({
        ...prevState,
        chartObject: {
          ...prevState.chartObject, // Correctly spread the existing chartObject
          ...val, // Merge the new values from val into chartObject
        },
        favorite: {
          ...prevState.favorite,
          selectedChartView: val.selectedChartView,
          selectedChartDef: val.selectedChartView,
          selectedTargetId: val.selectedTargetId,
        },
        showChart: val.showChart,
        currentStep: val.currentStep,
      }));
    },
    [debug]
  );

  const chartObjecTargetHandler = useCallback(
    (val) => {
      if (debug) console.log("chartObjecTargetHandler");
      setState((prevState) => ({
        ...prevState,
        showChart: true,
      }));
    },
    [debug]
  );

  // reset currentStep if 3 (causes one additional render)
  useEffect(() => {
    if (state.currentStep === 3) {
      if (debug) console.log("Reset sequential steps to 0");
      setState((prevState) => ({
        ...prevState,
        currentStep: 0,
      }));
    }
  }, [state.currentStep, debug]);
  // Memoize the props passed to ChartGraphWorkspace to prevent rendering
  const memoizedChartObject = useMemo(
    () => state.chartObject,
    [state.chartObject]
  );
  const memoizedStep = useMemo(() => state.currentStep, [state.currentStep]);
  const memoizedFavorite = useMemo(() => state.favorite, [state.favorite]);
  const memoizedSelectedCampus = useMemo(
    () => state.selectedCampus,
    [state.selectedCampus]
  );
  const memoizedDateRangeSelection = useMemo(
    () => state.dateRangeSelection,
    [state.dateRangeSelection]
  );
  const memoizedCategory = useMemo(
    () => state.selectedCategory,
    [state.selectedCategory]
  );

  // console.log("Show charts? ", state.showChart, state.currentStep === 0, state)
  const title = t("CHARTS");

  return (
    <Workspace isLoading={false} title={title}>
      <>
        {isLoadingCampuses ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100vh" }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : allCampuses.length > 0 ? (
          <>
            <ChartButtons
              favorite={memoizedFavorite}
              dateRangeSelection={memoizedDateRangeSelection}
              allCampuses={allCampuses}
              campusAndCategoryHandler={campusAndCategoryHandler}
              resetChartObject={resetChartObject}
              categoryHandler={categoryHandler}
              dateRangeHandler={dateRangeHandler}
              currentStep={memoizedStep}
            />
            {memoizedSelectedCampus.campusId !== null && (
              <>
                <Stack
                  direction={isMobile ? "column" : isTablet ? "column" : "row"}
                  spacing={2}
                  style={{
                    // paddingTop: "1rem",
                    // display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "left",
                  }}
                >
                  {memoizedCategory && memoizedCategory.chartGroupName && (
                    <>
                      <ChartTargetFilter
                        objectHandler={chartObjectHandler}
                        objecTargetHandler={chartObjecTargetHandler}
                        currentStep={memoizedStep}
                        selectedCampus={memoizedSelectedCampus}
                        selectedCategory={memoizedCategory}
                        chartObject={memoizedChartObject}
                      />
                      {state.showChart &&
                        memoizedChartObject.selectedTargetId &&
                        memoizedChartObject.selectedItem !== null &&
                        state.currentStep === 0 && (
                          <ChartGraphWorkspace
                            chartObject={memoizedChartObject}
                            selectedCampus={memoizedSelectedCampus}
                            dateRangeSelection={memoizedDateRangeSelection}
                          />
                        )}
                    </>
                  )}
                </Stack>
              </>
            )}
          </>
        ) : (
          <>
            {/* Conditionial rendering causes problems in workspace component. Do not delete this */}
          </>
        )}
      </>
    </Workspace>
  );
};

export default Charts;
