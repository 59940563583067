import React, { useEffect, useState, useCallback, memo } from "react";

import ChartBlock from "./ChartBlock";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import {
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useDrawerWidth } from "../../size";
import {
  fetchChartDescription,
  fetchChartTimeSeriesData,
} from "../actions/api";
import {
  getChartBlockSizes,
  convertLocalTimestampToUtc,
  convertArrayWithUtcEpochsToLocalEpochs,
} from "./chartFuncs";
import { SIDEBAR_WIDTH_SM } from "../../constants";
import { Translation } from "react-i18next";

/**
 * Simple debounce function
 */
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}

/**
 * Component defining the view section resolution of a chart page.
 * @param {Object} sharedHeaderObject - Object with campus and chart selection data.
 * @param {Object} targetSelection - Object with target selection data
 * @param {Object} dateRangeSelection - Object with date range data.
 */
const ChartGraphWorkspace = memo(
  ({ selectedCampus, chartObject, dateRangeSelection }) => {
    // Turn debug logs on/off
    const debug = false;

    const { selectedTargetId, charts, chartViewData } = chartObject;
    const { startDateTime, endDateTime } = dateRangeSelection;
    const { customerID, campusId, campusTimeZone } = selectedCampus;

    const idContainer = "graphContainer";

    // Layout queries
    const drawerWidth = useDrawerWidth();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery((theme) =>
      theme.breakpoints.between("sm", "md")
    );
    const isLargeTablet = useMediaQuery((theme) =>
      theme.breakpoints.between("md", "lg")
    );
    const boxWidth = isMobile ? "100%" : "100%";

    // States
    const [dataSets, setDataSets] = useState([]);
    // const [annotations, setAnnotations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [blockSizeReady, setBlockSizeReady] = useState(false);
    const [blockSize, setBlockSize] = useState([]);
    const [chartDescriptions, setChartDescriptions] = useState([]);
    const [isStable, setIsStable] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);

    // Debug logs each render
    if (debug) {
      console.log("***** ChartGraphWorkspace RENDER *****");
      console.log("selectedCampus:", selectedCampus);
      console.log("chartObject:", chartObject);
      console.log("dateRangeSelection:", dateRangeSelection);
      console.log("charts:", charts);
      console.log("dataSets:", dataSets);
      console.log("blockSize:", blockSize);
      console.log("dataReady:", dataReady, "blockSizeReady:", blockSizeReady);
      console.log("isDataReady:", isDataReady, "isStable:", isStable);
    }

    /**
     * Resize logic: we only do it once data is loaded
     */
    const handleResize = useCallback(() => {
      if (!charts || !charts.length) {
        if (debug) console.log("handleResize => No charts => skip");
        return;
      }
      if (!dataReady) {
        if (debug) console.log("handleResize => data not ready => skip");
        return;
      }
      if (debug) console.log("handleResize => computing new block sizes...");

      try {
        const sizes = charts
          .sort((a, b) => a.index - b.index)
          .map((chart) =>
            getChartBlockSizes(idContainer, drawerWidth, chart.dataSeries, [
              isMobile,
              isTablet,
              isLargeTablet,
            ])
          );
        setBlockSize(sizes);
        setBlockSizeReady(true);
        if (debug) console.log("handleResize => blockSize set:", sizes);
      } catch (err) {
        console.error("handleResize => error computing sizes:", err);
      }
    }, [
      charts,
      dataReady,
      debug,
      drawerWidth,
      idContainer,
      isMobile,
      isTablet,
      isLargeTablet,
    ]);

    // On window resize
    useEffect(() => {
      handleResize();
      const debouncedResize = debounce(handleResize, 100);
      window.addEventListener("resize", debouncedResize);
      return () => window.removeEventListener("resize", debouncedResize);
    }, [handleResize]);

    /**
     * If campus changes => reset data
     */
    useEffect(() => {
      if (campusId) {
        if (debug) console.log("Campus changed => reset dataSets + dataReady");
        setDataSets([]);
        setLoading(true);
        setDataReady(false);
      }
    }, [campusId, debug]);

    /**
     * If chartViewData is empty => reset data
     */
    useEffect(() => {
      if (chartViewData && chartViewData.length === 0) {
        if (debug) console.log("chartViewData empty => setDataSets([])");
        setDataSets([]);
      }
    }, [chartViewData, debug]);

    /**
     * Fetch chart descriptions if campus changes
     */
    useEffect(() => {
      if (customerID && campusId) {
        if (debug)
          console.log("Fetching chartDescriptions for:", customerID, campusId);
        const fetchData = async () => {
          try {
            const newDescriptions = await fetchChartDescription(
              customerID,
              campusId
            );
            setChartDescriptions(newDescriptions);
            if (debug)
              console.log("fetched chartDescriptions:", newDescriptions);
          } catch (err) {
            console.error("Error fetching chart descriptions:", err);
          }
        };
        fetchData();
      }
    }, [customerID, campusId, debug]);

    /**
     * Main data fetching effect:
     * We re-fetch whenever 'charts', 'startDateTime', or 'endDateTime' changes.
     */
    useEffect(() => {
      // We only fetch if we have some charts + valid date range
      if (!charts || charts.length === 0 || !startDateTime) {
        if (debug) {
          console.log(
            "No charts or missing startDate => set dataSets=[] and skipping fetch"
          );
        }
        setDataSets([]);
        return;
      }

      // function to do the actual fetch calls
      const fetchData = async (idList, modeList, startDt, endDt) => {
        try {
          if (debug) {
            console.log("fetchData => calling fetchChartTimeSeriesData with:", {
              idList,
              modeList,
              startDt,
              endDt,
            });
          }
          // startDt and endDt should be in UTC
          // both values are relative start of day, end of day values

          const data = await fetchChartTimeSeriesData(
            idList,
            modeList,
            startDt,
            endDt,
            "uplot"
          );
          return data;
        } catch (error) {
          console.error("ChartGraphWorkspace: Error fetching data:", error);
          return [];
        }
      };

      // function that updates dataSets for all charts
      const updateDataSets = async (_charts, _start, _end) => {
        if (debug) {
          console.log("updateDataSets => start fetching for charts:", _charts);
          console.log("updateDataSets => dateRange:", _start, "to", _end);
        }
        setDataReady(false);
        setLoading(true);

        try {
          const promises = _charts.map(async (ch) => {
            if (!ch.dataSeries) {
              if (debug) console.log("No dataSeries => returning [] for:", ch);
              return [];
            }
            // flatten points + modes
            const pointList = ch.dataSeries.flatMap((series) => series.points);
            const modeList = ch.dataSeries.flatMap((series) => series.hisMode);
            if (debug) {
              console.log(
                `updateDataSets => chart '${ch.title}' pointList=`,
                pointList
              );
            }
            return await fetchData(pointList, modeList, _start, _end);
          });

          const newDataSetValues = await Promise.all(promises);
          if (debug) {
            console.log(
              "updateDataSets => done, newDataSetValues:",
              newDataSetValues
            );
          }
          // time information is in sec since epoch UTC
          // convert them into local epochs
          const convertedData =
            convertArrayWithUtcEpochsToLocalEpochs(newDataSetValues);
          // console.log("---------EPOCH got data---", newDataSetValues, convertedData)
          // setDataSets(convertedData);
          setDataSets(newDataSetValues);
        } catch (error) {
          console.error("Error updating data sets:", error);
          setDataSets([]);
        } finally {
          setLoading(false);
          setDataReady(true);
          if (debug) console.log("updateDataSets => all done, dataReady=true");
        }
      };

      // Always re-fetch if charts or date changes
      setDataSets([]); // clear old data

      // Convert relative timestamps into UTC timestamps, based on the campus Timezone

      const startUtc = convertLocalTimestampToUtc(
        startDateTime,
        campusTimeZone
      );
      const endUtc = convertLocalTimestampToUtc(endDateTime, campusTimeZone);

      // console.log("---------EPOCH---", startDateTime, startUtc, endUtc)
      // console.log("bbbbbb", startDateTime, startUtc, endUtc)
      updateDataSets(charts, startUtc, endUtc);
    }, [charts, startDateTime, endDateTime, debug, campusTimeZone]);

    /**
     * Now we compute if everything is ready => blockSize & data
     */
    const currentDataReady =
      charts.length === dataSets.length && // same number of charts & data sets
      blockSize.length === charts.length && // block sizes exist for each chart
      selectedTargetId != null &&
      startDateTime != null &&
      dataSets.length > 0 &&
      charts.length > 0;

    if (debug) {
      console.log(">>> currentDataReady:", currentDataReady);
      console.log(">>> dataSets.length:", dataSets.length);
      console.log(">>> blockSize.length:", blockSize.length);
      console.log(">>> charts.length:", charts.length);
      console.log(">>> selectedTargetId:", selectedTargetId);
    }

    /**
     * 500ms stability check
     */
    useEffect(() => {
      let timer;
      if (currentDataReady) {
        timer = setTimeout(() => {
          setIsStable(true);
          if (debug) console.log("Stability 500ms => isStable=true");
        }, 500);
      } else {
        setIsStable(false);
      }
      return () => clearTimeout(timer);
    }, [currentDataReady, debug]);

    /**
     * Once stable => isDataReady = true
     */
    useEffect(() => {
      if (isStable) {
        setIsDataReady(true);
        if (debug) console.log("isDataReady => true");
      } else {
        setIsDataReady(false);
        if (debug) console.log("isDataReady => false");
      }
    }, [isStable, debug]);

    /**
     * Loading spinner
     */
    const Loading = () => {
      if (debug) console.log(">>> RENDERING LOADING COMPONENT <<<");
      return (
        <Box
          id={idContainer}
          sx={{
            width: boxWidth,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "16px",
            marginTop: "10rem",
          }}
        >
          <CircularProgress color="primary" />
          <Typography variant="body1" sx={{ mt: 2 }}>
            <Translation>
              {(t) => t("LOAD_DATA") || "Loading data..."}
            </Translation>
          </Typography>

          {/* ***** Adding a debug to check the staus and progress ***** */}

          {/* {debug && (
            <Typography variant="body2" sx={{ mt: 2, color: "red" }}>
              Debug info: loading={String(loading)}, dataReady=
              {String(dataReady)}, isDataReady={String(isDataReady)}
              <br />
              dataSets.length={dataSets.length}, blockSize.length=
              {blockSize.length}, charts.length={charts.length}
            </Typography>
          )} */}
        </Box>
      );
    };

    // If final readiness isn't met => show spinner
    if (!isDataReady) {
      return <Loading />;
    }

    // Otherwise => show the charts
    if (debug) console.log(">>> RENDERING CHARTS <<<");
    return (
      <div
        id={idContainer}
        style={{
          width: boxWidth,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "left",
        }}
      >
        {charts
          ?.sort((a, b) => a.index - b.index)
          .map((chart, index) => {
            const uniqueKey = chart.spec
              ? `div_hist_plot_${chart.spec}_${index}`
              : `div_hist_plot_${index}`;

            if (
              chart.type === "oxoia.charts::HistorianChart" &&
              chart.dataSeries
            ) {
              if (debug) {
                console.log(
                  "Rendering ChartBlock for index:",
                  index,
                  "uniqueKey:",
                  uniqueKey
                );
              }
              return (
                <ChartBlock
                  key={uniqueKey}
                  loadingState={loading || dataSets.length === 0 ? 0 : 1}
                  blockSize={blockSize[index]}
                  chartKey={`chartblock-children-${uniqueKey}`}
                  title={chart.title}
                  chartSpec={chart.spec}
                  chartDefs={chart.dataSeries}
                  chartData={dataSets[index]}
                  chartDescriptions={chartDescriptions}
                  parentContainerId={idContainer}
                  divKey={`${idContainer}-${index}`}
                  kpiDefs={chartViewData ? chartViewData.kpis : []}
                  kpiData={["45%", 5, "OK"]} // placeholders
                  chartViewAttr={chartViewData}
                  chart={chart}
                  selectedCampus={selectedCampus}
                  id={`blockkey-${index}`}
                  // complete data for annotation management or further processing in children
                  completeSets={{
                    charts: charts,
                    dataSets: dataSets,
                    dateRangeSelection: dateRangeSelection,
                    selectedCampus: selectedCampus,
                  }}
                />
              );
            }
            if (debug)
              console.log(
                "Chart not recognized => loading/spinner. key=",
                uniqueKey
              );
            return <Loading key={uniqueKey} />;
          })}

        {/* Extra spacing for mobile */}
        <div
          style={{
            height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px`",
          }}
        />
      </div>
    );
  }
);

export default ChartGraphWorkspace;
