import React, { useCallback, useState, useEffect, memo } from "react";
import {
  IconButton,
  Stack,
  Box,
  Paper,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDrawerWidth } from "../../size";
import { useTranslation } from "react-i18next";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import StarIcon from "@mui/icons-material/Star";
import {
  createChartLink,
  fetchChartViews,
  fetchChartTargets,
} from "../actions/api";
import { BORDER_RADIUS } from "../../constants";
import FavoriteDrawer from "../Generic/FavoriteDrawer";
import { alpha } from "@mui/material/styles";
import { useNotification } from "../Generic/NotificationContext";
import GenericAutocomplete from "../Generic/GenericAutocomplete";
import BaseElement from "./DateRange/BaseElement";
import { hackDemo } from "./chartFuncs";
import { TreeService } from "./TreeService";

const ChartButtons = memo((props) => {
  const {
    favorite,
    allCampuses,
    campusAndCategoryHandler,
    resetChartObject,
    dateRangeHandler,
    currentStep,
  } = props;
  const drawerWidth = useDrawerWidth();

  const isEnabled =
    favorite.selectedCampusId &&
    favorite.selectedChartView &&
    favorite.selectedTargetId &&
    favorite.startDateTime &&
    favorite.endDateTime
      ? true
      : false;

  const debug = false;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const theme = useTheme();
  const { addNotification, addError } = useNotification(); // rerenders every few seconds
  // States
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState();
  const { t } = useTranslation();

  const [chartViewObject, setChartViewObject] = useState([]);

  const toCampusObject = useCallback(
    (rawData) => {
      const data = structuredClone(rawData);
      const {
        customerID,
        id,
        label,
        campus_haystack_model_id,
        country,
        timezone,
      } = data;

      // Check for null or undefined values
      if (
        [
          customerID,
          id,
          label,
          campus_haystack_model_id,
          country,
          timezone,
        ].some((value) => value == null)
      ) {
        addError(
          "An error occurred. One or more required fields are null or undefined. Log out and log in again. If the error persist, contact Oxoia."
        );
      }

      return {
        customerID,
        campusId: id,
        campusName: label,
        campusHaystackId: hackDemo(campus_haystack_model_id),
        campusCountry: country,
        campusTimeZone: timezone,
      };
    },
    [addError]
  );

  // Fetch chart views when campus changes
  const fetchChartViewData = useCallback(
    async (_campObject) => {
      try {
        // Get the language parameter from useTranslation
        const language = t.language || "de"; // Default to 'de' if language is undefined

        // console.log("Language for chart views:", language);

        const chartData = await fetchChartViews(
          _campObject.customerID,
          _campObject.campusId,
          language // Now dynamically gets the current language
        );

        // Check if chartData is populated
        // console.log("Fetched chartData:", chartData);

        if (chartData && chartData.length > 0) {
          const uniqueChartGroups = [
            ...new Set(chartData.map((item) => item.chartGroup)),
          ];
          // Add name and identifies

          const organizedChartData = uniqueChartGroups.map((name) => ({
            chartGroupName: name,
            subGroups: chartData
              .filter((item) => item.chartGroup === name)
              .map((subGroupItem) => ({
                ...subGroupItem, // Include all properties
              })),
          }));

          setChartViewObject(organizedChartData);
          return organizedChartData;
        } else {
          console.warn(
            "No chart data received for this campus. Keeping existing data"
          );
          return [];
        }
      } catch (error) {
        console.error("Error fetching chart views:", error);
        return [];
      }
    },
    [t.language]
  );

  // Read selectedCampus list from localStorage, select the first element as selectedCampus
  const [selectedCampus, setSelectedCampus] = useState(() => {
    const saved = localStorage.getItem("selectedCampus");
    const campus =
      saved === null || saved.length === 0
        ? null
        : JSON.parse(saved)
            .flat()
            .map((camp) => (camp ? { ...camp, label: camp.name } : {}))
            .slice(0, 1)[0];
    // update parent
    if (campus && allCampuses.length > 0) {
      // attention : customerID might not be availalbe, load from allCampuses
      const campusWithCustomerId = allCampuses.find((f) => f.id === campus.id);

      if (campusWithCustomerId) {
        const campObject = toCampusObject(campusWithCustomerId);
        fetchChartViewData(campObject);
      }
    } else {
      // Try to load from favorites
      const campusId = localStorage.getItem("chart_favorite_selectedCampusId");
      if (!campusId && allCampuses.length > 0)
        console.warn(
          "No campus found, cannot load chart view automatically. Select one manually."
        );
      else if (allCampuses.length === 0)
        console.error("Campus list not yet ready!");
      else {
        const campusWithCustomerId = allCampuses.find(
          (f) => f.id === campus.id
        );
        if (campusWithCustomerId) {
          const campObject = toCampusObject(campusWithCustomerId);
          fetchChartViewData(campObject);
        } else {
          addError("No access to the campus in this direct link / favorite.");
        }
      }
    }
    return campus;
  });

  const fetchChartTargetsDataManual = useCallback(
    async (obj) => {
      try {
        if (Object.values(obj).some((value) => !value)) {
          console.warn(
            "Missing data in data object to fetch chart targets:",
            obj
          );
          return []; // Return an empty array if data is missing
        } else {
          const {
            customerID,
            campusId,
            selectedSubGroupTarget,
            campusHaystackId,
            selectedChartViewDef,
            subGroup,
          } = obj;
          const oxoia_command = `${selectedSubGroupTarget},${campusHaystackId},${selectedChartViewDef}`;

          if (debug)
            console.log("Fetching chart targets with command:", oxoia_command);

          const targets = await fetchChartTargets(
            customerID,
            campusId,
            oxoia_command
          );
          if (debug) console.log("Received targets:", targets);

          // Return the new targets with additional data
          return targets.map((target) => ({
            ...target,
            chartSubGroup: subGroup.chartSubGroup,
            chartGroup: subGroup.chartGroup,
            chartDef: subGroup.chartDef,
            charts: subGroup.charts,
            target: subGroup.target,
          }));
        }
      } catch (error) {
        console.error("Error fetching chart targets:", error.message || error);
        return []; // Return an empty array on error
      }
    },
    [debug]
  );
  const fetchDataFromApi = useCallback(
    async (_selectedCategory, _selectedCampus) => {
      let newTargets = [];

      // Fetch data for each subgroup
      for (const k of _selectedCategory.subGroups) {
        const targetData = await fetchChartTargetsDataManual({
          customerID: _selectedCampus.customerID,
          campusId: _selectedCampus.campusId,
          campusHaystackId: _selectedCampus.campusHaystackId,
          selectedChartViewDef: k.chartViewDef,
          selectedSubGroupTarget: k.target,
          subGroup: k,
        });
        newTargets.push(targetData); // Collect the fetched data
      }
      // Set the complete target list, flatten the array
      const allNewTargets = newTargets.flat();

      const tree = TreeService.getTree(
        allNewTargets,
        _selectedCategory.subGroups[0].targetFilters
      );
      return [allNewTargets, tree];
    },
    [fetchChartTargetsDataManual]
  );

  // Update the complete parent object (campus and categories)
  const handleChange = useCallback(
    (group, _selectedCampus, step = 0) => {
      // Get entry with customerID and update parent
      const campusWithCustomerId = allCampuses.find(
        (f) => f.id === _selectedCampus.id
      );
      const campusObject = toCampusObject(campusWithCustomerId);
      const fetchTargetData = async () => {
        const data = await fetchDataFromApi(group, campusObject);

        campusAndCategoryHandler({
          campus: campusObject,
          selectedCategory: { ...group, reset: false },
          currentStep: step,
          treeData: data[1],
          allTargets: data[0],
        });
        return data;
      };
      fetchTargetData();

      // Update local state
      setSelectedTopics(group);
    },
    [campusAndCategoryHandler, allCampuses, toCampusObject, fetchDataFromApi]
  );

  const fetchData = useCallback(
    async (viewParsed, candidate) => {
      let newGroups = [];

      if (chartViewObject.length === 0) {
        const campObject = toCampusObject(candidate);
        const updated = await fetchChartViewData(campObject); // Await the promise

        // Filter the groups based on the updated data
        newGroups = updated.filter(
          (f) =>
            f.subGroups.filter((k) => k.chartViewDef === viewParsed).length > 0
        );
      } else {
        newGroups = chartViewObject.filter(
          (f) =>
            f.subGroups.filter((k) => k.chartViewDef === viewParsed).length > 0
        );
      }

      return newGroups; // Return the new groups
    },
    [chartViewObject, fetchChartViewData, toCampusObject]
  );

  useEffect(() => {
    const processData = async () => {
      if (allCampuses.length > 0 && currentStep === 1) {
        const fav = localStorage.getItem("chart_favorite_selectedCampusId");
        const view = localStorage.getItem("chart_favorite_selectedChartView");

        if (fav && view) {
          const favCampId = JSON.parse(fav);
          const viewParsed = JSON.parse(view);
          // look up campus based on id
          const candidate = allCampuses
            .filter((f) => f.id === favCampId)
            .map((camp) => (camp ? { ...camp, label: camp.name } : {}))[0];

          const groups = await fetchData(viewParsed, candidate);

          if (candidate) {
            setSelectedCampus(candidate);
            // Set back the local category selection to null
            setSelectedTopics(candidate);
            // Move to step 2
            handleChange(groups[0], candidate, 2);
          } else {
            addError("No access to the campus in this favorite.");
          }
          localStorage.removeItem("chart_favorite_selectedCampusId");
          localStorage.removeItem("chart_favorite_selectedChartView");
        } else {
          console.warn("Campus ID not yet extracted - rerender.");
        }
      }
    };
    processData(); // Call the processing function
  }, [allCampuses, addError, currentStep, handleChange, fetchData]);

  // 🆕 NEW useEffect to refetch chart data when language or campus changes
  useEffect(() => {
    if (selectedCampus) {
      const campusWithCustomerId = allCampuses.find(
        (f) => f.id === selectedCampus.id
      );
      if (campusWithCustomerId) {
        const campObject = toCampusObject(campusWithCustomerId);
        fetchChartViewData(campObject);
      }
    }
  }, [selectedCampus, t, allCampuses, toCampusObject, fetchChartViewData]);

  // Updater for favorite, requires allCampuses loaded, triggered by refresh
  // Items are written by fetchLinkData function to storage (Charts.js)
  // After reading the value, the storage value is deleted
  // useEffect(() => {
  //     if (allCampuses.length > 0 && currentStep === 1) {
  //         const fav = localStorage.getItem("chart_favorite_selectedCampusId");
  //         const view = localStorage.getItem("chart_favorite_selectedChartView");
  //         console.log("ChartButtons - useEffect - favorites", fav, view, chartViewObject)
  //         if (fav && view) {
  //             const favCampId = JSON.parse(fav)
  //             const viewParsed = JSON.parse(view)
  //             // look up campus based on id
  //             const candidate = allCampuses
  //                 .filter(f => f.id === favCampId)
  //                 .map((camp) => (camp ? { ...camp, label: camp.name } : {}))[0]

  //             let groups =[]
  //             if(chartViewObject.length === 0){

  //                     const campObject = toCampusObject(candidate)
  //                     const updated = await fetchChartViewData(campObject);
  //                     groups = updated.filter(f => f.subGroups.filter(k => k.chartViewDef === viewParsed).length > 0)

  //             } else {
  //                 groups = chartViewObject.filter(f => f.subGroups.filter(k => k.chartViewDef === viewParsed).length > 0)
  //             }
  //             console.log("ChartButtons - groups", groups, chartViewObject)

  //             if (candidate) {

  //                 setSelectedCampus(candidate)
  //                 // Set back the local category selection to null
  //                 setSelectedTopics(candidate)
  //                 // Move to step 2
  //                 handleChange(groups[0], candidate, 2)
  //             } else {
  //                 addError("No access to the campus in this favorite.")
  //             }
  //             localStorage.removeItem("chart_favorite_selectedCampusId");
  //             localStorage.removeItem("chart_favorite_selectedChartView");

  //         } else {
  //             addError("Campus ID missing in this favorite link.")
  //         }
  //     }
  //     console.log("ChartButtons - useEffect - favorites")
  // }, [allCampuses, chartViewObject, addError, currentStep, handleChange]);

  // Handlers
  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleCreateLink = async () => {
    if (!isEnabled) {
      addNotification("Select target and date range before saving link.");
      return;
    }
    try {
      const {
        selectedCampusId,
        selectedChartView,
        selectedChartDef,
        selectedTargetId,
        startDateTime,
        endDateTime,
      } = favorite;
      // TODO: Add dialog to change the name

      const uniqueName = `${selectedChartView} / ${selectedTargetId} \n ${new Date(
        startDateTime
      )
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, ".")}-${new Date(endDateTime)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .replace(/\//g, ".")}`;

      const linkData = {
        target_page: `${window.location.origin}/#/charts`, // Use window.location.origin
        page_data: {
          selectedCampusId,
          selectedChartView,
          selectedChartDef,
          selectedTargetId,
          startDateTime,
          endDateTime,
          type: "chart",
        },
        name: uniqueName,
      };

      const response = await createChartLink(linkData);
      if (response?.success) {
        const shareableLink = response.shareable_link;
        addNotification(`Link created successfully: ${shareableLink}`);
        // Optionally copy the link to clipboard if available.
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard
            .writeText(shareableLink)
            .then(() => {
              addNotification(`Link copied to clipboard: ${shareableLink}`);
            })
            .catch((err) => {
              console.error("Clipboard write failed:", err);
            });
        } else {
          console.warn("Clipboard API not available.");
        }
        setReload(true);
        setTimeout(() => {
          setReload(false);
        }, 500);
      } else {
        addError(
          "Failed to create the link. A similar link may already exist."
        );
      }
    } catch (error) {
      console.error("Error creating chart link:", error);
      addError("Error occurred while creating the link.");
    }
  };

  // Campus selection handler, update storage and object
  // Manual selection
  const handleSelectCampus = (val) => {
    if (val !== null && val !== undefined) {
      // update local state
      setSelectedCampus(val);

      // Set back the local category selection to null
      setSelectedTopics(null);
      // reset chartObject in parent (complete object is udatd later)
      resetChartObject();
      // update storage
      let saved = localStorage.getItem("selectedCampus");
      let camps = saved ? JSON.parse(saved) : [];
      let existingObject = camps.find((obj) => obj.id === val.id);
      if (!existingObject) {
        // replace item 0 or push if empty
        if (camps.length === 0) {
          camps.push(val);
        } else {
          camps[0] = val;
        }
        localStorage.setItem("selectedCampus", JSON.stringify(camps));
      }

      // modify and update object for further use, throw error if not valid
      // Get entry with customerID and update parent
      const campusWithCustomerId = allCampuses.find((f) => f.id === val.id);
      const campObject = toCampusObject(campusWithCustomerId);

      // call if a manual change takes place
      fetchChartViewData(campObject);
    }
  };

  // activate button only, of all data is valid
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: drawerWidth === 0 ? "space-between" : "left",
        flexWrap: "wrap",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          padding: "1rem",
          background: theme.palette.background.default,
          borderRadius: `${BORDER_RADIUS}px`,
          border: `1px solid ${theme.palette.primary.dark}`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          boxShadow: `2px 0px 50px ${alpha(theme.palette.primary.dark, 0.1)}`,
        }}
        //   elevation={PAPER_ELEVATION}
        elevation={0}
      >
        <Stack
          direction="row"
          spacing={1}
          // style={{ width: '100%' flexWrap="wrap"}}
          sx={{
            width: "100%",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "flex-start",
          }}
        >
          <GenericAutocomplete
            multiple={false}
            dataList={allCampuses}
            changeHandler={handleSelectCampus}
            value={selectedCampus}
            size={"small"}
            label={t("Campus")}
            style={{ width: isMobile ? "50%" : isTablet ? "200px" : "300px" }}
          />
          <Stack direction="row" spacing={1} style={{ marginLeft: "20px" }}>
            <IconButton
              id="save-button"
              disabled={!isEnabled}
              onClick={handleCreateLink}
            >
              <StarIcon />
            </IconButton>
            <IconButton
              id="favorite-button"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <BookmarksIcon />
            </IconButton>
          </Stack>
          {/* Force wrap after this element */}
          {(isTablet || isMobile) && (
            <Box sx={{ flexBasis: "100%", height: 0 }} />
          )}

          <BaseElement
            objectHandler={dateRangeHandler}
            currentStep={currentStep}
          />
          <FavoriteDrawer
            {...props}
            isOpen={drawerOpen}
            reload={reload}
            handleClose={handleClose}
          />
        </Stack>
      </Paper>
      <Stack
        direction="row"
        spacing={1}
        sx={{ paddingBottom: "1rem", mt: 2 }}
        useFlexGap
        flexWrap="wrap"
      >
        {chartViewObject.length > 0 &&
          // Sort the object before mapping
          chartViewObject
            .sort((a, b) => a.chartGroupName.localeCompare(b.chartGroupName))
            .map((group, index) => (
              <Chip
                key={`chip-${index}`}
                style={{
                  color: !selectedTopics
                    ? theme.palette.primary.contrastText
                    : selectedTopics.chartGroupName === group.chartGroupName
                    ? theme.palette.secondary.contrastText
                    : theme.palette.primary.contrastText,
                  backgroundColor: !selectedTopics
                    ? theme.palette.primary.main
                    : selectedTopics.chartGroupName === group.chartGroupName
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                }}
                label={t(group.chartGroupName)}
                onClick={() => handleChange(group, selectedCampus)}
              />
            ))}
      </Stack>
    </div>
  );
});
export default ChartButtons;
