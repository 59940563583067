import React, { useState, useEffect } from "react";
import { DateObject, Calendar } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import { BORDER_RADIUS } from "../../../constants";

import { styled } from "@mui/system";

// Define custom CSS styles using makeStyles
const StyledCalendar = styled(Calendar)(({ theme }) => ({
  // new style class for calendar

  "--primary-font": "Urbanist, sans-serif",
  "--rmdp-primary-calendarColor": theme.palette.secondary.main,
  "--rmdp-secondary-calendarColor": theme.palette.secondary.light,
  "--rmdp-secondary-calendarColor-text": theme.palette.secondary.dark,
  "--rmdp-shadow-calendarColor": theme.palette.info.light,
  "--rmdp-shadow-calendarColor-hover": theme.palette.info.light,
  "--rmdp-today-calendarColor": theme.palette.secondary.main,
  "--rmdp-hover-calendarColor": theme.palette.secondary.light,
  "--rmdp-deselect-calendarColor": theme.palette.secondary.dark,

  // Row with week days
  "& .rmdp-week-day": {
    // color: "var(--rmdp-primary-calendarColor)",
    color: theme.palette.text.primary,
    height: "28px !important",
    width: "28px !important",
  },

  //  Hover
  "& .rmdp-range-hover": {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    boxShadow: `0 0 3px ${theme.palette.secondary.light}`,
  },
  "& .rmdp-range": {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
    boxShadow: `0 0 3px ${theme.palette.secondary.light}`,
  },
  "&.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover": {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
  },

  // Arrows left/right
  "& .rmdp-arrow": {
    border: "solid var(--rmdp-primary-calendarColor)",
    borderWidth: "0 2px 2px 0",
  },
  "& .rmdp-arrow-container": {
    borderRadius: `${BORDER_RADIUS}px !important`,
    boxShadow: "0 0 3px var(--rmdp-secondary-calendarColor)",
  },

  "& .rmdp-arrow-container:hover": {
    borderRadius: `${BORDER_RADIUS}px !important`,
    background: theme.palette.secondary.light,
    // boxShadow: "0 0 3px var(--rmdp-secondary-calendarColor)",
  },

  "& .rmdp-panel-body::-webkit-scrollbar-thumb": {
    background: "var(--rmdp-primary-calendarColor)",
  },

  // Day definitions
  // all days
  "& .rmdp-day span": {
    // backgroundColor: "red !important",
    borderRadius: `${BORDER_RADIUS}px !important`,

    fontSize: "11px",
    left: "3px",
    top: "3px",
    right: "3px",
    bottom: "3px",
  },
  // today
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: `${theme.palette.primary.light} !important`,
    border: `1px solid ${theme.palette.primary.light} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
  },
  // Week definitions
  // all days
  "& .rmdp-day": {
    // backgroundColor: "red !important",
    color: theme.palette.text.primary,
    // backgroundColor: `${theme.palette.primary.dark} !important`,
    height: "28px !important",
    width: "28px !important",
  },
}));

const ChartDateRangeCalendar = ({
  rangeHandler,
  fullYear,
  actualValues,
  updateValues,
}) => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(actualValues[0]);

  // Update if parent value changes
  useEffect(() => {
    if (actualValues && actualValues.length > 1) {
      setCurrentDate(actualValues[0]); // Set the currentDate to the start of the new range
    }
  }, [actualValues]);

  const updatePanelValues = (vals) => {
    // Update only, if 2 values
    if (vals.length > 1) {
      updateValues(vals);
      // reset range
      rangeHandler(null);
    }
  };
  return (
    <StyledCalendar
      key={currentDate.unix}
      numberOfMonths={1}
      currentDate={currentDate} // show the start date
      weekStartDayIndex={JSON.parse(t("WEEKSTARTINDEX"))}
      weekNumber="W"
      weekDays={JSON.parse(t("WEEKDAYS"))}
      format={t("CALENDAR_FORMAT")}
      months={JSON.parse(t("MONTHS"))}
      minDate="2020/01/01"
      dateSeparator=" - "
      maxDate={new DateObject().add(7, "days")}
      showOtherDays
      value={actualValues}
      onChange={updatePanelValues}
      range={true}
      multiple={false}
      fullYear={fullYear}
      rangeHover
    />
  );
};
export default ChartDateRangeCalendar;
