import React, { useEffect, useState, memo } from "react";
import {
  Stack,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { Translation } from "react-i18next";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Workspace from "../PageStructure/Workspace";
import SaveIcon from "@mui/icons-material/Save";
import { useElementSize, useDrawerWidth } from "../../size";
import RestoreIcon from "@mui/icons-material/Restore"; // Import reset icon
import {
  SIDEBAR_WIDTH_SM,
  BORDER_RADIUS,
  PAPER_ELEVATION,
} from "../../constants";
import axios from "axios";

const ColorField = ({ index, text, setColorList, colorList }) => {
  const [color, setColor] = useState(colorList.colors[index]);

  const changeColor = (newColor, index) => {
    // console.log(`Changing color at index ${index} to ${newColor}`);
    setColor(newColor.toUpperCase());
    const newColorList = [...colorList.colors];
    newColorList[index] = newColor.toUpperCase();
    const modColor = { ...colorList, colors: newColorList };
    localStorage.setItem("colors", JSON.stringify(newColorList));
    setColorList(modColor);
  };

  return (
    <Card
      elevation={PAPER_ELEVATION}
      sx={{
        display: "flex",
        width: { xs: "300px", sm: "400px", md: "400px" },
        height: { xs: "150px", sm: "150px", md: "200px" },
        justifyContent: "space-between",
        overflow: "visible",
        borderRadius: `${BORDER_RADIUS}px`,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {text}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ marginTop: { xs: "0px", sm: "0px", md: "25px" } }}
          >
            <Translation>{(t) => <div>{t("ExistingCOL")}</div>}</Translation>
          </Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: color,
                width: "24px",
              }}
            ></div>
            <HexColorInput
              style={{ marginLeft: "5px", width: "80px" }}
              color={color}
              onChange={(val) => changeColor(val, index)}
            />
          </div>
        </CardContent>
      </Box>
      <Box>
        <HexColorPicker
          style={{
            height: useElementSize().colorHeight,
            width: useElementSize().colorHeight,
          }}
          color={color}
          onChange={(val) => changeColor(val, index)}
        />
      </Box>
    </Card>
  );
};

const ManualTheme = memo(({ setColorList, colorList }) => {
  const [loading, setLoading] = useState(false);
  const drawerWidth = useDrawerWidth();
  // console.log("render manual theme - received colors", colorList);

  // Fetch the user based theme when loading
  useEffect(() => {
    const fetchTheme = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found");
        return;
      }

      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      try {
        console.log("Fetching theme data...");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/user/theme/`,
          config
        );
        console.log("Theme data fetched:", response.data);

        if (response.status === 200) {
          const data = response.data;
          setColorList({
            colors: [
              data.primary_color || "#012A39",
              data.secondary_color || "#019C4B",
              data.error_color || "#FF0000",
              data.warning_color || "#FF8500",
              data.info_color || "#BCC7D3",
              data.success_color || "#009C4A",
              data.background_color || "#ffffff",
              data.paper_color || "#ffffff",
              data.header_color || "#012A39",
            ],
            mode: "light", // or 'dark' if you want to handle that
          });
        }
      } catch (error) {
        console.error("Error fetching theme data:", error);
      } finally {
        setLoading(false);
        console.log("Loading set to false");
      }
    };

    if (!colorList) fetchTheme();
  }, [colorList, setColorList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    const config = {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };

    const themeData = {
      primary_color: colorList.colors[0],
      secondary_color: colorList.colors[1],
      error_color: colorList.colors[2],
      warning_color: colorList.colors[3],
      info_color: colorList.colors[4],
      success_color: colorList.colors[5],
      background_color: colorList.colors[6],
      paper_color: colorList.colors[7],
      header_color: colorList.colors[8],
    };

    try {
      console.log("Saving theme data...");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/user/theme/`,
        themeData,
        config
      );
      if (response.status === 200) {
        console.log("Theme saved successfully!");
      }
    } catch (error) {
      console.error("Error saving theme:", error);
    }
  };

  const resetTheme = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const config = {
      headers: { Authorization: `Token ${token}` },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/user/theme/reset/`,
        {},
        config
      );

      if (response.status === 200) {
        // ✅ Fetch new theme settings from backend
        const updatedTheme = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/user/theme/`,
          config
        );

        if (updatedTheme.status === 200) {
          const data = updatedTheme.data;
          const newColors = {
            colors: [
              data.primary_color || "#012A39",
              data.secondary_color || "#019C4B",
              data.error_color || "#FF0000",
              data.warning_color || "#FF8500",
              data.info_color || "#BCC7D3",
              data.success_color || "#009C4A",
              data.background_color || "#FFFFFF",
              data.paper_color || "#FFFFFF",
              data.header_color || "#012A39",
            ],
            mode: "light",
          };

          // ✅ Update the local state with new theme colors
          setColorList(newColors);

          // ✅ Update local storage so the changes persist
          localStorage.setItem("colors", JSON.stringify(newColors.colors));
        }

        // ✅ Optional: Refresh the page to apply theme changes globally => works without
        // window.location.reload();
      }
    } catch (error) {
      console.error("Error resetting theme:", error);
    }
  };

  const renderColors = (colorList, setColorList) => {
    const boxStyle = {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      width: "100%",
    };
    return (
      <Box sx={boxStyle}>
        <Stack
          spacing={{ xs: 1, sm: 4, md: 4 }}
          direction={{ sm: "column", md: "row" }}
          sx={{
            alignItems: { xs: "center", sm: "center", md: "flex-start" },
            paddingBottom: { xs: 1, sm: 4, md: 4 },
          }}
        >
          <ColorField
            key="1"
            index={0}
            text={<Translation>{(t) => <div>{t("PRIMCOL")}</div>}</Translation>}
            colorList={colorList}
            setColorList={setColorList}
          />
          <ColorField
            key="2"
            index={1}
            text={<Translation>{(t) => <div>{t("SECCOL")}</div>}</Translation>}
            colorList={colorList}
            setColorList={setColorList}
          />
          <ColorField
            key="9"
            index={8}
            text={
              <Translation>{(t) => <div>{t("HEADERCOL")}</div>}</Translation>
            }
            colorList={colorList}
            setColorList={setColorList}
          />
        </Stack>

        <Stack
          spacing={{ xs: 1, sm: 4, md: 4 }}
          direction={{ sm: "column", md: "row" }}
          sx={{
            alignItems: { xs: "center", sm: "center", md: "flex-start" },
            paddingBottom: { xs: "2rem", sm: 4, md: 4 },
          }}
        >
          <ColorField
            key="7"
            index={6}
            text={<Translation>{(t) => <div>{t("BACKG")}</div>}</Translation>}
            colorList={colorList}
            setColorList={setColorList}
          />
          <ColorField
            key="8"
            index={7}
            text={
              <Translation>{(t) => <div>{t("BACKGPAPER")}</div>}</Translation>
            }
            colorList={colorList}
            setColorList={setColorList}
          />
        </Stack>
      </Box>
    );
  };

  const AddEntry = () => {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          className="workspace"
          startIcon={<SaveIcon />}
          onClick={(e) => handleSubmit(e)}
        >
          <Translation>{(t) => <div>{t("SAVE")}</div>}</Translation>
        </Button>

        <Button
          variant="outlined"
          className="workspace"
          startIcon={<RestoreIcon />}
          onClick={resetTheme}
        >
          <Translation>{(t) => <div>{t("RESET")}</div>}</Translation>
        </Button>
      </Stack>
    );
  };

  return (
    <Workspace
      isLoading={loading}
      title={<Translation>{(t) => <div>{t("COLORS")}</div>}</Translation>}
      addTitleElementRightSide={<AddEntry />}
    >
      {colorList && renderColors(colorList, setColorList)}

      {/* Special block for mobile devices, do not delete */}
      <div
        style={{ height: drawerWidth === 0 ? `${SIDEBAR_WIDTH_SM}px` : "0px" }}
      ></div>
    </Workspace>
  );
});

export default ManualTheme;
